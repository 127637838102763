import React, { useContext } from 'react';

import { gql, useQuery } from '@apollo/client';

import config from '../config';

interface TenantConfigType {
  enablePortal: boolean;
  enableCATDemo: boolean;
  enableChat: boolean;
  phoneNumber: string;
  tenant: string;
  displayName: string;
  forceHomeFlow: boolean;
  investigationOnlyMode: boolean;
  disableNewClaimFiling?: boolean;
  enabledFNOLModes?: ('auto' | 'property')[];
}

interface GetTenantConfig {
  tenantConfig: string;
}

const GET_TENANT_CONFIG = gql`
  query GetTenantConfig {
    tenantConfig
  }
`;

const TenantConfigContext = React.createContext<TenantConfigType | null>(null);

export const TenantConfigProvider: React.FC<{}> = ({ children }) => {
  const { demoPreviewModeTenant } = config;

  const { loading, error, data } = useQuery<GetTenantConfig>(
    GET_TENANT_CONFIG,
    { skip: !!demoPreviewModeTenant },
  );
  const value = data
    ? (JSON.parse(data.tenantConfig) as TenantConfigType)
    : null;

  return (
    <TenantConfigContext.Provider
      value={
        demoPreviewModeTenant
          ? ({ tenant: demoPreviewModeTenant } as TenantConfigType)
          : value
      }
    >
      {children}
    </TenantConfigContext.Provider>
  );
};

export function useTenantConfig() {
  return useContext(TenantConfigContext);
}
