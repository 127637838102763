import './Seatmap.css';

import classNames from 'classnames';
import React, { useState } from 'react';

import FiveSeat from './Seatmap/05Seat';
import EightSeat from './Seatmap/08Seat';
import TeslaSeat from './Seatmap/Tesla';

const SEATS: Record<number, any> = {
  5: FiveSeat,
  8: EightSeat,
};

interface SeatmapProps {
  numSeats: number;
  selected?: string[];
  allowAnySeat?: boolean;
  disabledSeats?: string[];
  small?: boolean;
  onClick?: (id: string) => void;
}
const Seatmap: React.FC<SeatmapProps> = ({
  numSeats,
  selected,
  allowAnySeat,
  disabledSeats,
  small,
  onClick,
}) => {
  // Generate Unique ID to prevent namespace conflict on style when multiple
  // <Seatmap/> rendered on page.
  const [id, _] = useState(
    `Seatmap_${Math.random().toString(36).substring(2, 15)}`,
  );

  const isTesla = document.body.classList.contains('tenant-tesla');
  const SELECTED_FILL = isTesla ? '#fff' : '#3B82F6';

  const seat = SEATS.hasOwnProperty(numSeats) ? numSeats : 5;

  const Inner = isTesla ? TeslaSeat : SEATS[seat];
  return (
    <div
      id={id}
      className={classNames(
        `Seatmap -mb-6 mx-auto`,
        onClick && 'interactive',
        small && 'SeatmapSmall',
        allowAnySeat && 'SeatmapAllowAnySeat',
        isTesla && (small ? '-mt-2 -mb-4' : '-mt-16 -mb-16'),
      )}
      onClick={
        onClick
          ? (e: React.MouseEvent<HTMLInputElement>) => {
              const el = (e.target as HTMLInputElement).closest('g[id]');
              if (el && el.id) {
                if (
                  (!allowAnySeat && el.id === 'DRIVER') ||
                  disabledSeats?.includes(el.id)
                ) {
                  // Don't allow selecting driver
                  return;
                }
                onClick(el.id);
              }
            }
          : undefined
      }
    >
      <Inner />
      <style>{`
        ${(selected || []).map(s => `#${id}.Seatmap #${s}`).join(', ')} {
          fill: ${SELECTED_FILL};
        }
      `}</style>
      <style>{`
        ${(disabledSeats || []).map(s => `#${id}.Seatmap #${s}`).join(', ')} {
          user-select: none;
          fill: var(--light-color);
          cursor: initial;
         }
      `}</style>
    </div>
  );
};

export default Seatmap;
