import './Base.css';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';

import { TranslateIcon } from '@heroicons/react/solid';

import LanguageSwitcher from '../components/LanguageSwitcher';
import config from '../config';
import loader from '../images/loader.svg';

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  footer?: React.ReactNode;
  narrow?: boolean;
  fullBleed?: boolean;
  compact?: boolean;
  tenant?: string;
  hidePoweredBy?: boolean;
  expandedLanguageSwitcher?: boolean;
}

const Base: React.FC<BaseProps> = ({
  children,
  className,
  containerClassName,
  loading,
  footer,
  narrow,
  fullBleed,
  compact,
  tenant,
  hidePoweredBy,
  expandedLanguageSwitcher,
  ...rest
}) => {
  const [isReady, setIsReady] = useState(false);
  const [compactLanguageSwitcherOpen, setCompactLanguageSwitcherOpen] =
    useState(false);

  // Global tenant styles
  useEffect(() => {
    if (tenant) {
      document.body.classList.add(`tenant-${tenant}`);
    }

    if (tenant === 'tesla') {
      if (!document.getElementById('assured__customFontLoader')) {
        const link = document.createElement('link');
        link.id = 'assured__customFontLoader';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href =
          'https://cloud.typography.com/6056098/6174612/css/fonts.css';
        document.head.appendChild(link);
        link.onload = () => setIsReady(true);
      } else {
        setIsReady(true);
      }
    } else {
      setIsReady(true);
    }

    if (!config.publicAccessMode) {
      if (!document.getElementById('assured__flexLoader')) {
        const script = document.createElement('script');
        script.id = 'assured__flexLoader';
        script.src =
          'https://assets.flex.twilio.com/releases/flex-webchat-ui/2.5.0/twilio-flex-webchat.min.js';
        document.head.appendChild(script);
      }
    }

    // Never remove currently since tenant doesn't change once page mounted.
    // Led to some weird bugs on re-render when loading deferred chunk component.
    // return () => {
    //   document.body.classList.remove(`tenant-${tenant}`);
    // };
  }, [tenant]);

  if (!isReady) {
    return null;
  }

  return (
    <Div100vh
      style={{ minHeight: '100rvh' }}
      className={classNames(
        fullBleed
          ? 'flex flex-col items-center md:max-w-screen-lg mx-auto sm:pb-12'
          : `flex flex-col items-center justify-center px-6 ${
              compact ? 'py-6' : 'py-24'
            }`,
        className,
      )}
    >
      <div
        className={classNames(
          'Base relative',
          fullBleed
            ? 'pt-28 sm:pt-36 w-full overflow-x-hidden pb-1 flex-1 px-6 BaseFadeIn'
            : 'pt-12 pb-10 px-8 md:px-16 bg-white text-center rounded shadow',
          narrow && 'BaseNarrow',
          containerClassName,
        )}
      >
        {loading ? (
          <div
            className="absolute rounded top-0 left-0 right-0 bottom-0 flex items-center justify-center"
            style={{
              backgroundColor:
                tenant === 'tesla'
                  ? 'rgba(0,0,0,0.5)'
                  : 'rgba(255,255,255,0.5)',
              zIndex: 1,
            }}
          >
            <img src={loader} className="inline-block" />
          </div>
        ) : null}
        {children}
      </div>
      {footer}
      {(children || loading) && tenant !== 'tesla' && !hidePoweredBy ? (
        <div className="my-4 text-cool-gray-500 text-xs text-center">
          {config.publicAccessMode || process.env.REACT_APP_LOCALIZE_KEY ? (
            <>
              {process.env.REACT_APP_LOCALIZE_KEY &&
              !expandedLanguageSwitcher &&
              !compactLanguageSwitcherOpen ? (
                <div className="flex items-center">
                  <button
                    className="px-3 py-1 focus:outline-none focus:shadow-outline-gray bg-cool-gray-400 hover:bg-cool-gray-500 text-white rounded-full inline-flex items-center"
                    onClick={() => setCompactLanguageSwitcherOpen(true)}
                  >
                    <TranslateIcon className="w-4 h-4" />
                  </button>
                  <span className="mx-2">&middot;</span>
                  <span>Powered by Assured</span>
                </div>
              ) : process.env.REACT_APP_LOCALIZE_KEY ? (
                <>
                  <LanguageSwitcher
                    className="mt-2 mb-5"
                    onChange={() => setCompactLanguageSwitcherOpen(false)}
                  />
                  <div>Powered by Assured</div>
                </>
              ) : (
                <div>Powered by Assured</div>
              )}
            </>
          ) : (
            <>
              Assured Insurance Technologies Inc.
              <br />
              Proprietary and Confidential
            </>
          )}
        </div>
      ) : null}
    </Div100vh>
  );
};

export default Base;
