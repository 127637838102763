import classNames from 'classnames';
import Compressor from 'compressorjs';
import EXIF from 'exif-js';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { default as _ } from 'react-s3-uploader';
import S3Upload from 'react-s3-uploader/s3upload';

import { UploadStepComponent } from '@common/types/ClaimWorkflow';

import config from '../../config';
import { useMobileDetect } from '../../hooks';
import ExampleIllustration from '../elements/ExampleIllustration';
import ProgressBar from '../ProgressBar';
import { StepComponentFC, StepComponentSharedProps } from './types/stepComponentTypes';

const VideoRecorder = React.lazy(() => import('../VideoRecorder'));

interface PreviewableFile extends File {
  preview: string;
  exifData: any;
}
interface UploadValueInner {
  source: string;
  lastModifiedAt: string | null;
  exifData: string;
  [key: string]: any;
}
type UploadValue = UploadValueInner | (UploadValueInner | { id: string })[];

interface UploadProps
  extends StepComponentSharedProps<UploadStepComponent, UploadValue | null> {
  forceSubmit: () => void;
  additionalButtons: React.ReactNode;
}

const Upload: StepComponentFC<UploadProps> = ({
  step_component,
  primaryValue,
  updateValue,
  forceSubmit,
  className,
  additionalButtons,
  workflowChannel,
  onSidekickCommand,
}) => {
  const { isMobile } = useMobileDetect();

  const [files, setFiles] = useState<PreviewableFile[]>([]);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [dispatchedUpload, setDispatchedUpload] = useState(false);

  const uploadFile = (files: File[]) => {
    const uploader = new S3Upload({
      files: files,
      signingUrl: '/uploads/sign',
      uploadRequestHeaders: { 'x-amz-acl': 'private' },
      onProgress: p => setProgress(p / 100),
      onFinishS3Put: onFinish,
      onError: onError,
      contentDisposition: 'auto',
      server: config.endpoint,
      scrubFilename: filename => filename.replace(/[^\w\d_\-.]+/gi, ''),
      s3path: 'user-media/',
      getSignedUrl: function (file, cb) {
        // Use s3 accelerate
        this.executeOnSignedUrl(file, (result: { signedUrl: string }) => {
          result.signedUrl = result.signedUrl.replace(
            /s3\.amazonaws\./,
            's3-accelerate.amazonaws.',
          );
          cb(result);
        });
      },
      preprocess: (file, next) => {
        if (step_component.mode === 'video') {
          next(file);
          setFiles(existingFiles =>
            existingFiles.concat(
              [file].map(
                file =>
                  ({
                    ...file,
                    preview: URL.createObjectURL(file),
                  } as PreviewableFile),
              ),
            ),
          );
          return;
        }

        // Exif data
        EXIF.getData(file as any, () => {
          // window.EXIF = EXIF;
          let tags = EXIF.getAllTags(file);
          if (tags && tags.MakerNote) {
            delete tags.MakerNote;
          }

          // Compression
          new Compressor(file, {
            quality: 0.6,

            success(result) {
              setFiles(existingFiles =>
                existingFiles.concat(
                  [result].map(
                    file =>
                      ({
                        ...file,
                        preview: URL.createObjectURL(file),
                        exifData: tags,
                      } as PreviewableFile),
                  ),
                ),
              );
              next(result as File);
            },
            error(e) {
              if (file && file.type) {
                if (!file.type.match(/image[/]/)) {
                  window.alert('Please upload an Image file.');
                } else if (file.type.match(/image[/]heic/)) {
                  window.alert(
                    'Please convert this file to a jpg, or jpeg file and try again',
                  );
                }
              }
            },
          });
        });
      },
    });
  };

  const { getRootProps, getInputProps, rejectedFiles, isDragActive } =
    useDropzone({
      onDrop: acceptedFiles => {
        uploadFile(acceptedFiles);
      },
      accept: step_component.mode === 'image' ? 'image/*' : undefined,
      multiple: step_component.multiple || false,
    });

  const onError = (e: string) => {
    console.log(e);
    window.alert(JSON.stringify(e));
  };

  const onFinish = (data: any) => {
    setUploadedData(d => d.concat([data]));
  };

  useEffect(() => {
    if (
      userConfirmed &&
      uploadedData.length &&
      uploadedData.length === files.length &&
      !dispatchedUpload
    ) {
      const values = uploadedData.map(d => {
        const file =
          files.find(f => d.filename.indexOf(f.name) !== -1) || files[0];
        return {
          ...step_component.value,
          source: d.filename,
          lastModifiedAt: file.lastModified
            ? new Date(file.lastModified).toISOString()
            : null,
          exifData: JSON.stringify(file.exifData),
        };
      });
      setDispatchedUpload(true);
      updateValue(
        step_component.field,
        step_component.multiple
          ? (
              step_component.existing_hidden_values || ([] as UploadValue)
            ).concat(values)
          : values[0],
      );
    }
  }, [userConfirmed, uploadedData, files, dispatchedUpload]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  if (workflowChannel === 'sidekick') {
    return (
      <div className={classNames(className, 'mt-4')}>
        <button
          onClick={() => {
            // Send sidekick backend request
            onSidekickCommand?.({ type: 'DIGITAL_REQUEST_MEDIA' });
            // Skip to next step
            forceSubmit();
          }}
          className="btn btn-blue"
        >
          Send media request
        </button>
        <div className="">
          <button className="btn btn-subtle" onClick={forceSubmit}>
            Never mind
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(className, 'mt-4')}>
      {files.length === 0 ? (
        <React.Fragment>
          {step_component.example_illustration && (
            <ExampleIllustration type={step_component.example_illustration} />
          )}
          {step_component.mode === 'video' ? (
            <VideoRecorder
              className="mb-6 rounded-md overflow-hidden"
              onSubmit={blob => {
                setUserConfirmed(true);
                uploadFile([new File([blob], 'video')]);
              }}
            />
          ) : (
            <div
              {...getRootProps()}
              className={classNames(
                'py-4 rounded border-4 border-dashed border-cool-gray-400 text-cool-gray-600 cursor-pointer bg-cool-gray-100 outline-none transition ease-in-out duration-150',
                isDragActive && 'bg-cool-gray-200',
              )}
              style={{ paddingLeft: '22%', paddingRight: '22%' }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the image here...</p>
              ) : (
                <p>
                  {isMobile
                    ? `Tap here to take or upload ${
                        step_component.multiple
                          ? 'one or more pictures'
                          : 'a picture'
                      }`
                    : `Drag and drop ${
                        step_component.multiple
                          ? 'one or more photos'
                          : 'a photo'
                      } here, or click to select`}
                </p>
              )}
            </div>
          )}
          {(
            Array.isArray(step_component.existing_source)
              ? step_component.existing_source.length
              : step_component.existing_source
          ) ? (
            <div className="mt-4 btn btn-subtle mx-0" onClick={forceSubmit}>
              Use previously uploaded image
              {(Array.isArray(step_component.existing_source)
                ? step_component.existing_source
                : [step_component.existing_source]
              ).map(
                source =>
                  source && (
                    <img
                      key={source}
                      src={source}
                      className="h-20 mt-2 mx-auto"
                    />
                  ),
              )}
            </div>
          ) : null}
          {!step_component.unskippable ? (
            <div
              className="mt-4 btn btn-subtle mx-0 py-2"
              onClick={forceSubmit}
            >
              {step_component.skip_label ||
                `I can't take ${
                  step_component.multiple ? 'these photos' : 'this photo'
                }`}
            </div>
          ) : null}
          {additionalButtons}
        </React.Fragment>
      ) : (
        <div className="outline-none">
          {step_component.mode !== 'video' ? (
            files.map(file => (
              <img
                key={file.name}
                src={file.preview}
                className="h-48 my-6 mx-auto border shadow-xl"
              />
            ))
          ) : (
            <div className="my-6" />
          )}
          {userConfirmed ? (
            <div>
              <div className="text-sm text-cool-gray-600 mb-2">
                Uploading {step_component.mode === 'video' ? 'video' : 'photo'}
                {files.length > 1 ? 's' : ''}...
              </div>
              <ProgressBar progress={progress} className="w-64 mx-auto" />
            </div>
          ) : (
            <button
              onClick={() => setUserConfirmed(true)}
              className="btn btn-blue"
            >
              Upload photo{files.length > 1 ? 's' : ''}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default Upload;
