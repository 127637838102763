import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useDebounce from 'react-use/lib/useDebounce';

import { ClinicalTableSearchStepComponent } from '@common/types/ClaimWorkflow';
import { XIcon } from '@heroicons/react/solid';

import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';

type Awaited<T> = T extends Promise<infer U> ? U : T;

interface ClinicalTableSearchProps
  extends StepComponentSharedProps<
    ClinicalTableSearchStepComponent,
    any[] | any
  > {
  forceSubmit: () => void;
}

const QUERIES = {
  npi_individual: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.phone',
    ],
  },
  npi_organization: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.phone',
    ],
  },
  npi_hospital: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: 'provider_type:*hospital*',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.phone',
    ],
  },
  rxterm: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search`,
    q: '',
    fields: [
      'SXDG_RXCUI',
      'DISPLAY_NAME',
      'STRENGTHS_AND_FORMS',
      'RXCUIS',
      'DISPLAY_NAME_SYNONYM',
    ],
  },
} as const;

const query = async (mode: keyof typeof QUERIES, term: string) => {
  if (!term) {
    return { term, total_matched: 0, results: [] };
  }

  const spec = QUERIES[mode];
  const params = new URLSearchParams({
    terms: term,
    q: spec.q,
    df: spec.fields.join(','),
    maxList: '5',
  });
  const res = await fetch(`${spec.endpoint}?${params}`).then(r => r.json());
  const [total_matched, codes, _, fields, __]: [
    number,
    string[],
    undefined,
    string[],
    undefined,
  ] = res;
  return {
    term,
    total_matched,
    results: codes.map((code, resultIndex) => {
      return {
        _id: code,
        ...(spec.fields as unknown as string[]).reduce(
          (obj, k, fieldIndex) => ({
            ...obj,
            [k]: fields[resultIndex][fieldIndex],
          }),
          {},
        ),
      } as Record<'_id' | typeof spec.fields[number], any>;
    }),
  };
};

const ClinicalTableSearch: StepComponentFC<ClinicalTableSearchProps> = ({
  step_component,
  updateValue,
  primaryValue,
  className,
  forceSubmit,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('');
  const [, cancel] = useDebounce(
    () => setDebouncedSearchTerm(searchTerm),
    100,
    [searchTerm],
  );

  type QueryRes = Awaited<ReturnType<typeof query>>;
  type QueryResultItem = QueryRes['results'][number];
  const [searchRes, setSearchRes] = useState<QueryRes | null>(null);

  // Since the results of the query() call can come back in a nondeterministic order,
  // only apply a result if a later result has not yet been returned. This prevents
  // race conditions where stale data overwrites the res *after* newer data has already
  // been loaded successfully.
  const queryOrderCounter = useRef({ applied: -1, dispatched: -1 });
  useEffect(() => {
    const queryIdx = ++queryOrderCounter.current.dispatched;
    query(step_component.mode, debouncedSearchTerm).then(r => {
      if (queryIdx > queryOrderCounter.current.applied) {
        queryOrderCounter.current.applied = queryIdx;
        setSearchRes(r);
      }
    });
  }, [debouncedSearchTerm]);

  const [selectedResults, setSelectedResults] = useState<QueryResultItem[]>(
    (step_component.list
      ? step_component.existing_results
      : step_component.existing_result
      ? [step_component.existing_result]
      : []
    )?.map(p =>
      step_component.mode === 'rxterm'
        ? p
        : {
            id: p.id,
            ...p.npiData,
          },
    ) || [],
  );

  const selectItem = (item: QueryResultItem) => {
    if (!selectedResults.find(rItem => rItem._id === item._id)) {
      setSelectedResults(results => [...results, item]);
    }
    setSearchRes(null);
    setSearchTerm('');
    setDebouncedSearchTerm('');
  };

  const deselectItem = (item: QueryResultItem) => {
    setSelectedResults(results => results.filter(i => i !== item));
  };

  return (
    <div className={classNames('mt-4 relative', className)}>
      {selectedResults ? (
        <div className="">
          {selectedResults.map(item => {
            return (
              <div
                key={item._id}
                className="bg-cool-gray-50 py-2 px-4 rounded-md border border-cool-gray-300 mb-4 text-left flex items-center text-cool-gray-800"
              >
                <span className="flex-1 font-medium">
                  {item['name.full'] || item['DISPLAY_NAME']}
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => deselectItem(item)}
                >
                  <XIcon className="w-4 h-4 text-cool-gray-400 hover:text-red-500" />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      {step_component.list || selectedResults.length === 0 ? (
        <>
          <input
            type="string"
            className="textbox"
            placeholder={
              selectedResults.length
                ? 'Start typing to add another..'
                : 'Start typing to search...'
            }
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <div className="">
            {searchTerm &&
            searchTerm === searchRes?.term &&
            searchRes?.results?.length === 0 ? (
              <div className="my-4 text-cool-gray-400 font-medium">
                No results found &ndash; try another search
              </div>
            ) : (
              searchRes?.results.map(r => {
                return (
                  <div
                    key={r._id}
                    className="mt-4 p-4 cursor-pointer text-left rounded-md border border-cool-gray-300 hover:bg-cool-gray-50"
                    onClick={() => selectItem(r)}
                  >
                    {step_component.mode === 'rxterm' ? (
                      <>
                        <div className="font-medium text-cool-gray-800">
                          {r['DISPLAY_NAME']}
                        </div>
                        <div className="text-sm font-medium text-cool-gray-600">
                          {r.STRENGTHS_AND_FORMS?.split(',')
                            .map((x: string) => x.trim())
                            .join(', ')}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="font-medium text-cool-gray-800">
                          {r['name.full']}
                        </div>
                        <div className="text-sm font-medium text-cool-gray-600">
                          {r.provider_type}
                        </div>
                        <div className="text-sm text-cool-gray-500">
                          {r['addr_practice.full']}
                        </div>
                      </>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </>
      ) : null}
      <div className="mt-4 flex justify-center flex-wrap sm:flex-no-wrap">
        <button
          className={classNames(
            'btn btn-blue sm:order-last',
            !selectedResults.length && 'btn-disabled',
          )}
          onClick={() => {
            if (selectedResults.length) {
              if (step_component.mode === 'rxterm') {
                const value = selectedResults;
                updateValue(
                  step_component.field,
                  step_component.list
                    ? value.map(v => JSON.stringify(v))
                    : JSON.stringify(value[0]),
                );
              } else {
                const value = selectedResults.map(r => ({
                  id: (r as any).id,
                  name: r['name.full'],
                  npi: r.NPI,
                  npiData: r,
                }));
                updateValue(
                  step_component.field,
                  step_component.list ? value : value[0],
                );
              }
            }
          }}
        >
          {step_component.submit_label ||
            (step_component.list ? "That's everyone" : 'Continue')}
        </button>
        <button
          className="btn btn-subtle sm:order-first"
          onClick={() => forceSubmit()}
        >
          {step_component.skip_label ||
            (step_component.list ? "Didn't see anyone" : 'No one')}
        </button>
      </div>
    </div>
  );
};

ClinicalTableSearch.stepConfig = {};

export default ClinicalTableSearch;
