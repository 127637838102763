import classNames from 'classnames';
import React from 'react';

interface IncrementButtonProps {
  direction: 'positive' | 'negative';
  disabled?: boolean;
  value: number;
  updateValue: (n: number) => void;
}

const IncrementButton: React.FC<IncrementButtonProps> = ({
  direction,
  disabled,
  value,
  updateValue,
}) => {
  return (
    <button
      className={classNames(
        'w-12 h-12 flex items-center justify-center rounded-full border-2 border-cool-gray-300 bg-cool-gray-100 focus:outline-none focus:shadow-outline-blue mx-3 select-none',
        disabled && 'opacity-50 pointer-events-none',
      )}
      style={{ touchAction: 'manipulation' }}
      onClick={
        disabled
          ? undefined
          : () =>
              updateValue(
                Math.max(0, (value || 0) + (direction === 'positive' ? 1 : -1)),
              )
      }
    >
      <div className="leading-none text-center text-3xl font-bold text-cool-gray-500 -mt-1">
        {direction === 'positive' ? '+' : '−'}
      </div>
    </button>
  );
};

export default IncrementButton;
