import React from 'react';
import classNames from 'classnames';

interface LabelProps {
  step_component: {
    step_component_id?: string;
    label?: string;
    required?: boolean;
    optional?: boolean;
  };
  className?: string;
}

const Label: React.FC<LabelProps> = ({ step_component, className }) => {
  return (
    <label
      className={classNames(
        'block text-cool-gray-700 text-sm font-semibold mb-2 text-left',
        className,
      )}
      htmlFor={step_component.step_component_id}
    >
      {step_component.label}
      {step_component.optional ? (
        <span className="ml-2 text-cool-gray-400 text-xs uppercase">
          Optional
        </span>
      ) : null}
      {/* {step_component.required ? <span className="text-red-500">*</span> : null} */}
    </label>
  );
};
export default Label;
