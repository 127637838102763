import './App.css';
import 'react-select/dist/react-select.css';

import React, { lazy, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import CustomRoute from './components/CustomRoute';
import PageLoader from './components/PageLoader';
import config from './config';
import { TenantConfigProvider } from './hooks/useTenantConfig';
import { apolloClient } from './services/apollo';
import { Auth0Provider } from './services/auth';

/* eslint-disable import/first */
const DamageRenderer = lazy(() => import('./pages/DamageRenderer'));
const SidekickRenderer = lazy(() => import('./pages/SidekickRenderer'));

const Activate = lazy(() => import('./pages/Activate'));
const Home = lazy(() => import(/* webpackPrefetch: true */ './pages/Home'));
const ManagePolicy = lazy(() => import('./pages/ManagePolicy'));
const Cards = lazy(() => import('./pages/Cards'));
const ClaimWorkflow = lazy(
  () => import(/* webpackPrefetch: -1 */ './pages/ClaimWorkflow'),
);
const Catastrophe = lazy(() => import('./pages/Catastrophe'));
// const Claim = lazy(() => import('./pages/Claim'));
const Begin = lazy(() => import(/* webpackPrefetch: true */ './pages/Begin'));
const BeginAuthenticatedDeepLink = lazy(
  () => import('./pages/BeginAuthenticatedDeepLink'),
);
const BeginLinkInvestigation = lazy(
  () => import('./pages/BeginLinkInvestigation'),
);

const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));

const CollisionReconstructionRenderer = lazy(
  () => import('./pages/CollisionReconstructionRenderer'),
);
const CollisionReconstruction = lazy(
  () => import('./pages/CollisionReconstruction'),
);

const App = () => {
  const history = useHistory();

  return (
    <div className="App">
      <Auth0Provider
        domain={config.authDomain}
        client_id={config.authClientId}
        audience={config.authAudience}
        redirect_uri={config.authRedirectUri}
        logout_redirect_uri={config.authRedirectUri}
        onRedirectCallback={data => {
          if (data.target) {
            history.replace(data.target + (data.search || ''));
          }
        }}
      >
        <ApolloProvider client={apolloClient}>
          <TenantConfigProvider>
            <Suspense fallback={<PageLoader />}>
              <Switch>
                <CustomRoute exact path="/activate" component={Activate} />
                <CustomRoute
                  exact
                  path="/damage_renderer/:claimId"
                  component={DamageRenderer}
                />
                <CustomRoute
                  exact
                  path="/sidekick_renderer"
                  component={SidekickRenderer}
                />
                <CustomRoute
                  exact
                  path="/collision_demo"
                  component={CollisionReconstruction}
                />
                <CustomRoute
                  exact
                  path="/collision_renderer"
                  component={CollisionReconstructionRenderer}
                />
                <CustomRoute
                  path="/"
                  component={Home}
                  authenticationRequired={!config.publicAccessMode}
                  exact
                />
                <CustomRoute exact path="/begin" component={Begin} />
                <CustomRoute
                  exact
                  path="/auth_begin"
                  component={BeginAuthenticatedDeepLink}
                />
                <CustomRoute
                  path="/link/:splat*"
                  component={BeginLinkInvestigation}
                />
                <CustomRoute
                  path="/policy"
                  component={ManagePolicy}
                  authenticationRequired
                />
                <CustomRoute
                  path="/cards/:policyId"
                  component={Cards}
                  authenticationRequired
                />
                <CustomRoute
                  path="/cards"
                  component={Cards}
                  authenticationRequired
                />
                <CustomRoute
                  path="/workflows/:workflowId"
                  component={ClaimWorkflow}
                  authenticationRequired
                />
                <CustomRoute
                  path="/cat/:catastropheId/:view?/:subview?"
                  component={Catastrophe}
                  authenticationRequired
                />
                <CustomRoute path="/terms" component={Terms} />
                <CustomRoute path="/privacy" component={Privacy} />
                {/* <CustomRoute
                  path="/claims/:claimId"
                  component={Claim}
                  authenticationRequired
                /> */}
              </Switch>
            </Suspense>
          </TenantConfigProvider>
        </ApolloProvider>
      </Auth0Provider>
    </div>
  );
};

export default App;
