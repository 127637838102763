import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

interface BodilyInjuryDetailModalTabsProps {
  tabs: {
    key: string;
    label: string;
    count?: number;
  }[];
  currentTab?: string;
  singleRegionPicker?: boolean;
  onTabSelect: (k: string) => void;
}

const BodilyInjuryDetailModalTabs: React.FC<
  BodilyInjuryDetailModalTabsProps
> = ({ tabs, onTabSelect, currentTab, singleRegionPicker }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const target = containerRef.current?.querySelector(
      `[data-tab-key="${currentTab}"]`,
    ) as HTMLElement;

    if (target) {
      containerRef.current?.scroll({
        top: 0,
        left:
          target.offsetLeft -
          (containerRef.current?.parentElement?.clientWidth || 0) / 2 +
          (target.clientWidth - 64) / 2,
        behavior: 'smooth',
      });
    }
  }, [currentTab]);

  return (
    <div
      className={classNames(
        'overflow-y-hidden overflow-x-scroll pb-3',
        singleRegionPicker ? '-mx-10 sm:-mx-16' : '-mx-48',
      )}
      ref={r => {
        containerRef.current = r;
      }}
    >
      <div
        className="border-b border-gray-200 min-w-full"
        style={{ width: 'max-content' }}
      >
        <nav
          className={classNames(
            '-mb-px flex',
            singleRegionPicker ? 'px-10 sm:px-16' : 'px-48',
          )}
          aria-label="Tabs"
        >
          {tabs.map((tab, i) => (
            <button
              key={tab.key}
              data-tab-key={tab.key}
              className={classNames(
                tab.key === currentTab
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500',
                'whitespace-no-wrap flex items-center py-2 px-1 border-b-2 font-medium text-sm focus:outline-none',
                i < tabs.length - 1 && 'mr-6',
              )}
              aria-current={tab.key === currentTab ? 'page' : undefined}
              onClick={() => onTabSelect(tab.key)}
            >
              {tab.label}
              {tab.count ? (
                <span className="bg-blue-100 text-blue-600 ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium -my-2">
                  {tab.count}
                </span>
              ) : null}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default BodilyInjuryDetailModalTabs;
