import classNames from 'classnames';
import React from 'react';

import { VehicleSeatmapWidget } from '@common/types/ClaimWorkflow';

import Seatmap from '../../Seatmap';
import { WidgetComponentSharedProps, WidgetFC } from '../types/widgetComponentTypes';

const SeatmapWidget: WidgetFC<
  WidgetComponentSharedProps<VehicleSeatmapWidget>
> = ({ widget, className }) => {
  return (
    <div className={classNames(className, 'mt-4 mb-4 text-center')}>
      <Seatmap
        numSeats={widget.num_seats}
        selected={widget.selected_seats}
        allowAnySeat
        small={true}
      />
    </div>
  );
};

export default SeatmapWidget;
