import acl_meniscus from '../../../images/bodily_injury/acl_meniscus.svg';
import amputation from '../../../images/bodily_injury/amputation.svg';
import back_sprain from '../../../images/bodily_injury/back_sprain.svg';
import broken_bone from '../../../images/bodily_injury/broken_bone.svg';
import bruised_bone from '../../../images/bodily_injury/bruised_bone.svg';
import concussion from '../../../images/bodily_injury/concussion.svg';
import cut_scrape from '../../../images/bodily_injury/cut_scrape.svg';
import dislocated from '../../../images/bodily_injury/dislocated.svg';
import genital_damage from '../../../images/bodily_injury/genital_damage.svg';
import headache from '../../../images/bodily_injury/headache.svg';
import hearing_loss from '../../../images/bodily_injury/hearing_loss.svg';
import hernia from '../../../images/bodily_injury/hernia.svg';
import herniated_disk from '../../../images/bodily_injury/herniated_disk.svg';
import hyperextension from '../../../images/bodily_injury/hyperextension.svg';
import neck_pain from '../../../images/bodily_injury/neck_pain.svg';
import puncture from '../../../images/bodily_injury/puncture.svg';
import soreness from '../../../images/bodily_injury/soreness.svg';
import sprain_strain from '../../../images/bodily_injury/sprain_strain.svg';
import vertebrae_fracture from '../../../images/bodily_injury/vertebrae_fracture.svg';
import vision_loss from '../../../images/bodily_injury/vision_loss.svg';
import whiplash from '../../../images/bodily_injury/whiplash.svg';

const icons: { [k: string]: string } = {
  acl_meniscus,
  back_sprain,
  broken_bone,
  bruised_bone,
  broken_toe: broken_bone,
  broken_finger: broken_bone,
  broken_rib: broken_bone,
  broken_ankle: broken_bone,
  broken_collarbone: broken_bone,
  bruised_rib: bruised_bone,
  concussion,
  cut_scrape,
  dislocated,
  dislocated_hip: dislocated,
  dislocated_knee: dislocated,
  genital_damage,
  headache,
  hyperextension,
  hyperextension_finger: hyperextension,
  neck_pain,
  puncture,
  soreness,
  sprain_strain,
  sprain_wrist: sprain_strain,
  whiplash,
  amputation,
  amputation_foot: amputation,
  amputation_finger: amputation,
  amputation_toe: amputation,
  hearing_loss,
  herniated_disk,
  hernia,
  vertebrae_fracture,
  vision_loss,
  default: soreness,
};

export default icons;
