import classNames from 'classnames';
import React, { useState } from 'react';

import { ChecklistStepComponent } from '@common/types/ClaimWorkflow';

import { StepComponentFC, StepComponentSharedProps } from './types/stepComponentTypes';

interface ChecklistProps
  extends StepComponentSharedProps<ChecklistStepComponent, string | null> {
  forceSubmit: () => void;
}

const Checklist: StepComponentFC<ChecklistProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  forceSubmit,
}) => {
  const [checked, setChecked] = useState<string[]>([]);
  const canSubmit =
    step_component.advisory_only ||
    step_component.options.every(o => checked.includes(o.key));

  return (
    <div className={classNames('mt-6', className)}>
      <div className="flex flex-col">
        {step_component.options.map(option => {
          const isChecked = checked.includes(option.key);
          return (
            <label
              key={option.key}
              htmlFor={option.key}
              className="mb-5 cursor-pointer"
            >
              <div className="flex items-center">
                <input
                  id={option.key}
                  type="checkbox"
                  checked={isChecked}
                  onChange={e => {
                    const isChecked = e.target.checked;
                    setChecked(checked =>
                      checked
                        .filter(c => c !== option.key)
                        .concat(isChecked ? [option.key] : []),
                    );
                  }}
                  className="form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-7 w-7 text-blue-600 transition duration-150 ease-in-out mr-3"
                />
                <div className="text-left">
                  <div
                    className={classNames(
                      '',
                      isChecked
                        ? 'font-medium text-cool-gray-400 line-through'
                        : 'font-medium text-cool-gray-600',
                    )}
                  >
                    {option.title}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  'text-left ml-10 mt-1',
                  isChecked
                    ? 'text-sm text-cool-gray-300 line-through'
                    : 'text-sm text-cool-gray-500',
                )}
              >
                {option.description}
              </div>
            </label>
          );
        })}
      </div>
      <button
        className={classNames(
          'btn btn-blue mt-4',
          !canSubmit && 'btn-disabled',
        )}
        onClick={canSubmit ? forceSubmit : undefined}
      >
        {step_component.advisory_only ? `Continue` : `All steps completed`}
      </button>
    </div>
  );
};

export default Checklist;
