import acl_meniscus from '../../../images/bodily_injury/usaa/acl_meniscus.svg';
import amputation from '../../../images/bodily_injury/usaa/amputation.svg';
import back_sprain from '../../../images/bodily_injury/usaa/back_sprain.svg';
import broken_bone from '../../../images/bodily_injury/usaa/broken_bone.svg';
import bruised_bone from '../../../images/bodily_injury/usaa/bruised_bone.svg';
import concussion from '../../../images/bodily_injury/usaa/concussion.svg';
import cut_scrape from '../../../images/bodily_injury/usaa/cut_scrape.svg';
import dislocated from '../../../images/bodily_injury/usaa/dislocated.svg';
import genital_damage from '../../../images/bodily_injury/usaa/genital_damage.svg';
import headache from '../../../images/bodily_injury/usaa/headache.svg';
import hearing_loss from '../../../images/bodily_injury/usaa/hearing_loss.svg';
import hernia from '../../../images/bodily_injury/usaa/hernia.svg';
import herniated_disk from '../../../images/bodily_injury/usaa/herniated_disk.svg';
import hyperextension from '../../../images/bodily_injury/usaa/hyperextension.svg';
import neck_pain from '../../../images/bodily_injury/usaa/neck_pain.svg';
import puncture from '../../../images/bodily_injury/usaa/puncture.svg';
import soreness from '../../../images/bodily_injury/usaa/soreness.svg';
import sprain_strain from '../../../images/bodily_injury/usaa/sprain_strain.svg';
import vertebrae_fracture from '../../../images/bodily_injury/usaa/vertebrae_fracture.svg';
import vision_loss from '../../../images/bodily_injury/usaa/vision_loss.svg';
import whiplash from '../../../images/bodily_injury/usaa/whiplash.svg';

const icons: { [k: string]: string } = {
  acl_meniscus,
  back_sprain,
  broken_bone,
  bruised_bone,
  broken_toe: broken_bone,
  broken_finger: broken_bone,
  broken_rib: broken_bone,
  broken_ankle: broken_bone,
  broken_collarbone: broken_bone,
  bruised_rib: bruised_bone,
  concussion,
  cut_scrape,
  dislocated,
  dislocated_hip: dislocated,
  dislocated_knee: dislocated,
  genital_damage,
  headache,
  hyperextension,
  hyperextension_finger: hyperextension,
  neck_pain,
  puncture,
  soreness,
  sprain_strain,
  sprain_wrist: sprain_strain,
  whiplash,
  amputation,
  amputation_foot: amputation,
  amputation_finger: amputation,
  amputation_toe: amputation,
  hearing_loss,
  herniated_disk,
  hernia,
  vertebrae_fracture,
  vision_loss,
  default: soreness,
};

export default icons;
