import React from 'react';
import classNames from 'classnames';

interface ToggleProps {
  options: {
    label: string;
    value: boolean;
  }[];
  value: boolean;
  onChange: (value: boolean) => void;
}
const Toggle: React.FC<ToggleProps> = ({
  options,
  value: selectedValue,
  onChange,
}) => {
  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      {options.map(({ label, value }, i) => {
        return (
          <button
            key={value as any}
            type="button"
            className={classNames(
              'relative flex-1 inline-flex justify-center items-center px-4 py-2 border text-sm leading-5 font-medium transition ease-in-out duration-75',
              i === 0
                ? 'rounded-l-md'
                : i === options.length - 1
                ? 'rounded-r-md -ml-px'
                : '-ml-px',
              value === selectedValue
                ? 'border-blue-400 bg-blue-500 text-white focus:outline-none'
                : 'border-cool-gray-300 bg-white text-cool-gray-700 hover:text-cool-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-cool-gray-100 active:text-cool-gray-700',
            )}
            style={{ WebkitTapHighlightColor: 'rgba(255, 255, 255, 0)' }}
            onClick={e => {
              onChange(value);
            }}
          >
            {label}
          </button>
        );
      })}
    </span>
  );
};

export default Toggle;
