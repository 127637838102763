import classNames from 'classnames';
import fetchJsonp from 'fetch-jsonp';
import React, { useEffect, useRef, useState } from 'react';
//FIXME(06-23-2020) Having trouble with typing react-select so I'm leaving it without types for now
// @ts-ignore
import ReactSelect, { AsyncCreatable, Creatable } from 'react-select';
// @ts-ignore
import defaultMenuRenderer from 'react-select/lib/utils/defaultMenuRenderer';

import { SelectStepComponent } from '@common/types/ClaimWorkflow';
import { CustomSelectValue, Option } from '@common/types/ClaimWorkflow/additional';

import { useMobileDetect, useTenantConfig } from '../../hooks';
import { formatPhoneNumber } from '../../utils';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Overdrive from '../Overdrive';
import Label from './Label';
import OtherModal from './OtherModal';
import { formatHighlightedTerms } from './Title';
import {
  StepComponentFC, StepComponentOtherValue, StepComponentSharedProps, StepComponentSourceValue
} from './types/stepComponentTypes';

type SelectExtendable = StepComponentSharedProps<
  SelectStepComponent,
  CustomSelectValue
> &
  Partial<StepComponentOtherValue<string> & StepComponentSourceValue<string>>;

interface SelectProps extends SelectExtendable {
  overdriveField?: string;
}

const Select: StepComponentFC<SelectProps> = ({
  step_component,
  primaryValue,
  otherValue,
  sourceValue,
  updateValue,
  overdriveField,
  className,
}) => {
  const tenantConfig = useTenantConfig();
  const { isMobile } = useMobileDetect();
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);

  // We track the last user-selected label in case there are multiple buttons with
  // the same value; this way we always know which specific one to highlight.
  const [lastSelectedLabel, setLastSelectedLabel] = useState<string | null>(
    null,
  );

  const refs = useRef<Record<string | number | any, Overdrive | null>>({});
  const forceUpdateOverdrive = () => {
    Object.values(refs.current).forEach(r => r && r.onHide());
    refs.current = {};
  };

  const creatableRef = useRef<Creatable<React.ReactElement> | null>(null);

  const tempSelectValue = useRef<Record<string, any> | null>(null);

  useEffect(() => {
    if (creatableRef.current && !step_component.no_scroll_into_view) {
      // FIXME(06-18-2020)confused how this is working
      const node = (creatableRef.current as any).select?.wrapper;
      if (node) {
        window.scroll({
          left: 0,
          top: (node as HTMLElement).getBoundingClientRect().y - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [creatableRef]);

  const convertToKey = (v: CustomSelectValue): number | string | any => {
    return v;
  };

  const undefinedGuard = (
    v: CustomSelectValue | undefined,
  ): CustomSelectValue => {
    return typeof v !== 'undefined' ? v : null;
  };

  const wrapChild = (
    content: React.ReactNode,
    v: CustomSelectValue,
    props: Record<string, any>,
  ) =>
    overdriveField ? (
      <Overdrive
        key={convertToKey(v)}
        id={`${overdriveField}-${v}`}
        duration={400}
        ref={r => {
          refs.current[convertToKey(v)] = r;
        }}
        {...props}
      >
        {content}
      </Overdrive>
    ) : (
      content
    );

  const currentValue = primaryValue;
  const existingOptions: Option<CustomSelectValue>[] =
    currentValue !== null
      ? [{ label: currentValue as any, value: currentValue }]
      : [];

  if (step_component.mode === 'mini_button_group') {
    return (
      <div
        className={classNames(
          className,
          'relative flex items-center justify-center -my-4',
        )}
        key={step_component.id}
      >
        {step_component.options?.map(({ label, value }) => {
          return (
            <button
              className={classNames(
                'btn text-xs px-2 py-2 mx-1 font-medium leading-3',
                currentValue === value ? 'btn-blue' : 'btn-subtle',
              )}
              onClick={() => {
                if (typeof value !== 'undefined') {
                  updateValue(step_component.field, value);
                }
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
    );
  } else if (step_component.mode === 'dropdown_basic') {
    return (
      <div
        className={classNames(className, 'relative')}
        key={step_component.id}
      >
        {step_component.label ? (
          <Label step_component={step_component} />
        ) : null}
        {wrapChild(
          <div className="absolute left-0 right-0 top-0 bottom-0" />,
          'dropdown_fixed',
          {},
        )}
        <Dropdown
          placeholder={step_component.placeholder}
          value={
            step_component.options?.find(o => o.value === currentValue)?.label
          }
          options={step_component.options?.map(o => o.label) || []}
          onChange={e => {
            const option = step_component.options?.find(
              o => o.label === e.target.value,
            );
            if (typeof option?.value !== 'undefined') {
              updateValue(step_component.field, option.value);
            }
          }}
        />
      </div>
    );
  } else if (step_component.mode === 'dropdown') {
    const Component = step_component.source
      ? step_component.source === 'us-states'
        ? ReactSelect
        : AsyncCreatable
      : Creatable;

    let options = step_component.options || [];

    if (step_component.source === 'us-states') {
      // prettier-ignore
      options = ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
        .map(v => ({ label: v, value: v }))
    } else if (step_component.source_items) {
      options = step_component.source_items.map(v => ({ label: v, value: v }));
    }

    return (
      <div
        className={classNames(
          className,
          'relative mt-4',
          step_component.subtle_with_textfield && 'mt-6 -mb-4',
        )}
        style={
          step_component.narrow
            ? { maxWidth: 250, marginLeft: 'auto', marginRight: 'auto' }
            : {}
        }
        key={step_component.id}
      >
        {step_component.label ? (
          <Label step_component={step_component} />
        ) : null}
        {wrapChild(
          <div className="absolute left-0 right-0 top-0 bottom-0" />,
          'dropdown',
          {},
        )}
        <Component
          ref={(r: Creatable<React.ReactElement>) => {
            creatableRef.current = r;
          }}
          className="text-left"
          placeholder={
            step_component.placeholder || 'Start typing to select...'
          }
          autoFocus={!primaryValue && !step_component.no_scroll_into_view}
          onChange={(v: { value: CustomSelectValue } | null) =>
            updateValue(step_component.field, v ? v.value : null)
          }
          options={options.concat(existingOptions)}
          showNewOptionAtTop={false}
          arrowRenderer={() => null}
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          value={primaryValue as any} //having trouble setting as anything other than an array
          promptTextCreator={(label: string) => label}
          menuRenderer={(props: {
            inputValue: string;
            focusedOption: Record<string, any>;
          }) => {
            if (!props.inputValue || props.inputValue.length < 2) {
              return null;
            }
            if (props.focusedOption) {
              tempSelectValue.current = props.focusedOption;
            }
            return defaultMenuRenderer(props);
          }}
          onBlur={() => {
            if (tempSelectValue.current) {
              updateValue(step_component.field, tempSelectValue.current.value);
              tempSelectValue.current = null;
            }
          }}
          loadOptions={
            step_component.source
              ? (
                  input: string,
                  cb: (
                    inputString: string | null,
                    obj: {
                      complete?: boolean;
                      options: {
                        value: string | number;
                        label: string | number;
                      }[];
                    },
                  ) => void,
                ) => {
                  if (step_component.source === 'car-make') {
                    fetchJsonp(
                      `https://www.carqueryapi.com/api/0.3/?cmd=getMakes`,
                    )
                      .then(r => r.json())
                      .then(d => {
                        cb(null, {
                          options: (d.Makes || [])
                            .map((m: any) => ({
                              label: m.make_display,
                              value: m.make_display,
                            }))
                            .concat(existingOptions),
                          complete: true,
                        });
                      });
                  } else if (
                    step_component.source === 'car-model' &&
                    sourceValue
                  ) {
                    fetchJsonp(
                      `https://www.carqueryapi.com/api/0.3/?cmd=getModels&make=${sourceValue}`,
                    )
                      .then(r => r.json())
                      .then(d => {
                        cb(null, {
                          options: d.Models.map((m: any) => ({
                            label: m.model_name,
                            value: m.model_name,
                          })).concat(existingOptions),
                          complete: true,
                        });
                      });
                  } else if (
                    step_component.source === 'car-year' &&
                    step_component.max &&
                    step_component.min
                  ) {
                    cb(null, {
                      options: new Array(
                        step_component.max - step_component.min + 1,
                      )
                        .fill(0)
                        .map((_, i) => ({
                          label: step_component.min! + i,
                          value: (step_component.min! + i).toString(),
                        }))
                        .reverse(),
                      complete: true,
                    });
                  } else {
                    cb(null, { options: [] });
                  }
                }
              : undefined
          }
        />
      </div>
    );
  }

  const isColor = step_component.mode === 'color';

  return (
    <div
      key={step_component.id}
      className={classNames(
        className,
        step_component.mode === 'wide' && 'flex-col',
        !step_component.grid_by && step_component.options?.length === 3
          ? 'grid grid-cols-1 sm:flex flex-wrap justify-center'
          : step_component.grid_by === 3
          ? 'flex flex-wrap justify-center sm:grid sm:grid-cols-3 gap-4 sm:mt-4'
          : 'flex flex-wrap justify-center',
        'mt-2 -m-2',
        step_component.grid_by_desktop ? 'sm:grid sm:grid-cols-3 sm:-mx-4' : '',
      )}
      style={isColor ? { maxWidth: 400, margin: '0 auto' } : {}}
    >
      <OtherModal
        onUpdate={v => {
          step_component.other_field &&
            updateValue(step_component.other_field, v);
        }}
        onSave={() => {
          setShowOtherInput(false);
          step_component.other_option &&
            updateValue(step_component.field, step_component.other_option);
        }}
        showInput={showOtherInput}
        prompt={step_component.other_prompt || 'Something else'}
        value={otherValue}
      />
      {step_component.options?.map(
        ({
          value: optionValue,
          action,
          label,
          label_style,
          sublabel,
          icon,
          icon_text,
          bigger_label,
          style,
          magic,
        }) => {
          const onClick = () => {
            const isOther = optionValue === step_component.other_option;
            if (action) {
              if (action === '911') {
                window.open('tel:911', '_blank');
              } else if (action === 'call') {
                window.open(`tel:${tenantConfig?.phoneNumber}`);
              } else if (action.indexOf('call:') === 0) {
                window.open(`tel:${action.substring(5)}`);
              } else if (action.indexOf('open:') === 0) {
                window.open(action.substring(5), '_blank');
              } else if (action.indexOf('mailto:') === 0) {
                window.open(action, '_blank');
              } else {
                window.alert('Dispatching action :: ' + action);
              }
            } else if (isOther) {
              setShowOtherInput(true);
            } else {
              forceUpdateOverdrive();
              updateValue(step_component.field, undefinedGuard(optionValue));
              setLastSelectedLabel(label);
            }
          };
          const accentOptions: Record<string, any> = {
            positive: {
              default:
                'border-green-200 bg-green-100 sm:hover:bg-green-100 sm:hover:border-green-300',
              selected: 'border-green-300',
              textTitle: 'text-green-600',
              textSubtitle: 'text-green-400',
            },
            negative: {
              default:
                'border-red-200 bg-red-100 sm:hover:bg-red-100 sm:hover:border-red-300',
              selected: 'border-red-300',
              textTitle: 'text-red-600',
              textSubtitle: 'text-red-400',
            },
          };
          const accent = style && accentOptions[style];

          if (action === 'call' && tenantConfig?.phoneNumber && !isMobile) {
            label = `Call ${formatPhoneNumber(tenantConfig?.phoneNumber)}`;
          }

          if (isColor) {
            // For Overdrive to work properly.
            const colorClass =
              'ClaimWorkflowColorButton-' + `${optionValue}`.replace('#', '');

            return (
              <React.Fragment key={convertToKey(undefinedGuard(optionValue))}>
                <style>{`.${colorClass} { background-color: ${optionValue}; }`}</style>
                {wrapChild(
                  <div
                    className={classNames(
                      `ClaimWorkflowColorButton ${colorClass} rounded-full shadow-inner border-2 border-cool-gray-400 m-2 hover:shadow-outline cursor-pointer`,
                      primaryValue
                        ? primaryValue === optionValue
                          ? ''
                          : 'opacity-25'
                        : '',
                    )}
                    onClick={onClick}
                  />,
                  undefinedGuard(optionValue),
                  {},
                )}
              </React.Fragment>
            );
          }

          return wrapChild(
            <button
              key={JSON.stringify(optionValue)}
              className={classNames(
                'flex-1 flex flex-col justify-center bg-white',
                magic ? 'btn btn-magic mt-4 py-4 mx-2' : 'select-btn',
                accent && `accented sm:hover:shadow ${accent.default}`,
                primaryValue === optionValue &&
                  (lastSelectedLabel === null || label === lastSelectedLabel) &&
                  (accent
                    ? accent.selected
                    : `bg-blue-100 border-blue-300 hover:bg-blue-100`),
                bigger_label && 'p-1 sm:p-3',
                step_component.grid_by === 3 && 'sm:m-0',
              )}
              style={
                step_component.grid_by
                  ? overdriveField
                    ? {}
                    : {
                        flexBasis: `${80 / step_component.grid_by}%`,
                      }
                  : {}
              }
              onClick={onClick}
            >
              <Icon icon={icon} text={icon_text} />
              <div
                className={classNames(
                  magic
                    ? 'text-center text-white w-full'
                    : 'text-cool-gray-600 leading-none text-lg',
                  accent && accent.textTitle,
                  bigger_label && 'text-2xl',
                  label_style === 'mono' && 'font-mono',
                )}
              >
                {formatHighlightedTerms(label)}
              </div>
              {sublabel ? (
                <div
                  className={classNames(
                    'text-cool-gray-600 text-sm mt-1 leading-tight',
                    accent && accent.textSubtitle,
                  )}
                >
                  {sublabel}
                </div>
              ) : null}
            </button>,
            undefinedGuard(optionValue),
            {
              style: step_component.grid_by
                ? {
                    display: 'flex',
                    flex: '1',
                    flexBasis: `${80 / step_component.grid_by}%`,
                  }
                : {},
            },
          );
        },
      )}
    </div>
  );
};

export default Select;
