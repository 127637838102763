import React, { useEffect, useState } from 'react';
import { ClaimWorkflowStepComponent } from '@common/types/ClaimWorkflow';
import { StepComponentType } from '@common/types/ClaimWorkflow';
import { WidgetType } from '@common/types/ClaimWorkflow';

import {
  StepComponentSharedProps,
  StepComponentShowsPrefillProps,
  StepComponentControlsBackHookProps,
  StepComponentControlsErrorProps,
  StepComponentControlsTitleProps,
  StepComponentOtherValue,
  StepComponentSourceValue,
} from './types/stepComponentTypes';
import { useStepComponentLookup, useWidgetLookup } from '../../hooks';

type GenericProps = StepComponentSharedProps<ClaimWorkflowStepComponent, any> &
  Partial<
    StepComponentOtherValue<any> &
      StepComponentSourceValue<any> &
      StepComponentShowsPrefillProps &
      StepComponentControlsBackHookProps &
      StepComponentControlsErrorProps &
      StepComponentControlsTitleProps
  > &
  Record<string, any>;

const Generic: React.FC<GenericProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  ...rest
}) => {
  // const Component = STEP_COMPONENTS[step_component.type];
  // const Widget = step_component.widget
  //   ? WIDGET_COMPONENTS[step_component.widget.type]
  //   : null;

  let Component = useStepComponentLookup(
    step_component.type as StepComponentType,
  );
  let Widget =
    step_component.widget &&
    useWidgetLookup(step_component.widget.type as WidgetType);

  return (
    <React.Fragment>
      {step_component.widget && Widget ? (
        <Widget className="ClaimWorkflowInner" widget={step_component.widget} />
      ) : null}
      {Component ? (
        <Component
          key={step_component.id}
          className={
            typeof className !== 'undefined'
              ? className
              : Widget != null
              ? 'ClaimWorkflowInnerDelay'
              : 'ClaimWorkflowInner'
          }
          step_component={step_component}
          primaryValue={primaryValue}
          updateValue={updateValue}
          Component={Component}
          {...rest}
        />
      ) : (
        <div>{JSON.stringify(step_component)}</div>
      )}
    </React.Fragment>
  );
};

export default Generic;
