import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { CollisionReconstructionStepComponent } from '@common/types/ClaimWorkflow';

import CollisionReconstructionComponent from '../CollisionReconstruction';
import LocationMap from './Location/LocationMap';
import {
  StepComponentControlsBackHookProps, StepComponentFC, StepComponentSharedProps
} from './types/stepComponentTypes';

interface CollisionReconstructionProps
  extends StepComponentSharedProps<
      CollisionReconstructionStepComponent,
      string | null
    >,
    StepComponentControlsBackHookProps {
  forceSubmit: () => void;
}

const CollisionReconstruction: StepComponentFC<
  CollisionReconstructionProps
> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
  registerBackHook,
  removeBackHook,
}) => {
  const [isResetting, setIsResetting] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const internalBackHook = useRef<(() => boolean) | null>(null);

  useEffect(() => {
    const backHook = () => {
      if (internalBackHook.current) {
        const res = internalBackHook.current();
        return res;
      }
      return false;
    };
    registerBackHook(backHook);
    return () => removeBackHook(backHook);
  }, [internalBackHook]);

  if (isResetting) {
    return null;
  }

  return (
    <div className={className}>
      <div className="mt-6">
        <CollisionReconstructionComponent
          initialData={hasReset ? undefined : step_component.existing_data}
          collisionLocation={
            step_component.location_center
              ? {
                  lat: step_component.location_center.latitude,
                  lng: step_component.location_center.longitude,
                }
              : { lat: 0, lng: 0 }
          }
          speedOptions={step_component.speed_options}
          selfWasStationary={step_component.self_was_stationary}
          otherWasStationary={step_component.other_was_stationary}
          selfLabel={step_component.self_label}
          otherPartyLabel={step_component.other_party_label}
          reset={() => {
            setIsResetting(true);
            setHasReset(true);
            setTimeout(() => setIsResetting(false), 10);
          }}
          submit={(data: any) => {
            updateValue(
              step_component.field,
              data ? JSON.stringify(data) : null,
            );
          }}
          registerBackHook={(f: () => boolean) => {
            internalBackHook.current = f;
          }}
        />
      </div>
    </div>
  );
};

export default CollisionReconstruction;
