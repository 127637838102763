import React from 'react';

import Modal from '../Modal';

interface OtherModalProps {
  showInput: boolean;
  onUpdate: (val: string) => void;
  onSave: () => void;
  prompt: string;
  value?: string;
}
const OtherModal: React.FC<OtherModalProps> = ({
  showInput,
  onUpdate,
  onSave,
  prompt,
  value,
}) => {
  return (
    <>
      <Modal
        open={showInput}
        title={prompt || 'Tell us more...'}
        body={
          <div className="my-4">
            <textarea
              className="textbox text-base text-cool-gray-900"
              placeholder=""
              autoFocus
              value={value}
              onChange={e => {
                onUpdate(e.target.value);
              }}
            />
          </div>
        }
        actions={[
          {
            title: 'Save',
            primary: true,
            onClick: () => {
              onSave();
            },
          },
        ]}
      />
    </>
  );
};

export default OtherModal;
