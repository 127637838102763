import { propertyCheckLocation } from './components/ClaimView';

let multiTenantSelectedTenant = process.env.REACT_APP_TENANT!;
if (multiTenantSelectedTenant === 'AUTOSELECT') {
  // If set to `AUTOSELECT`, we will attempt to guess the multi-tenant based
  // on the URL hostname. For example, auto-owners.assured.claims => autoowners.
  // If the hostname starts with demo..., we assume it's the main demo site, where
  // the tenant selection is based on the Demo/User, not the frontend site.
  const base = window.location.hostname
    .toLowerCase()
    .split('.')[0]
    ?.replace(/[^a-z]/g, '');
  if (base.indexOf('demo') === 0) {
    multiTenantSelectedTenant = '';
  } else {
    multiTenantSelectedTenant = base;
  }
}

const config: Record<string, string> = {
  endpoint: process.env.REACT_APP_ENDPOINT!,
  authRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI!,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
  authAudience: process.env.REACT_APP_AUTH_AUDIENCE!,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  publicAccessMode: process.env.REACT_APP_PUBLIC_ACCESS_MODE!,
  demoPreviewModeTenant: process.env.REACT_APP_DEMO_PREVIEW_MODE_TENANT!,
  multiTenantSelectedTenant,
};

export default config;
