import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import config from '../config';
import { getTokenSilently } from './auth';

const httpLink = new HttpLink({ uri: config.endpoint, credentials: 'include' });

const authLink = setContext(async (request, { headers }) => {
  let updatedHeaders = { ...headers };

  if (config.multiTenantSelectedTenant) {
    updatedHeaders['x-assured-tenant'] = config.multiTenantSelectedTenant;
  }

  // Don't use token for specific unauthenticated operations. Prevents issues from
  // expired tokens, since even if your old token is expired, it shouldn't matter because
  // we are looking up/creating a whole new one.
  const NO_AUTH_OPERATIONS = [
    'GetTenantConfig',
    'LookupDemo',
    'ActivateDemo',
    'AuthenticateUser',
    'LookupAuthenticatedDeepLink',
    'StartNewLinkInvestigationResponse',
  ];
  if (
    request.operationName &&
    NO_AUTH_OPERATIONS.indexOf(request.operationName) !== -1
  ) {
    return { headers: updatedHeaders };
  }

  let token;
  try {
    token = await getTokenSilently();
  } catch (e) {
    // fall-through if not logged in
  }

  if (token) {
    updatedHeaders = {
      ...updatedHeaders,
      authorization: `Bearer ${token}`,
    };
  }

  return { headers: updatedHeaders };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
