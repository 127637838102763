import classNames from 'classnames';
import React, { useState } from 'react';

import { Coordinate } from '@common/types/ClaimWorkflow/additional';
import { GoogleMap } from '@react-google-maps/api';

import { useLoadMaps } from '../../../hooks';
import mapMarker from '../../../images/icons/map-pin.png';
import loader from '../../../images/loader.svg';

interface LocationMapProps {
  center?: Coordinate | null;
  zoom?: number;
  mapTypeId?: string | null;
  loaded?: boolean;
  interactive?: boolean;
  onChange?: (location: Coordinate) => void;
  customMarker?: React.ReactElement | null;
  hideMarker?: boolean;
}

let map: google.maps.Map;

const LocationMap: React.FC<LocationMapProps> = ({
  center: c,
  zoom,
  mapTypeId,
  loaded,
  interactive,
  onChange,
  customMarker,
  hideMarker,
}) => {
  const { isLoaded } = useLoadMaps();

  const center = c ? { lat: c.latitude, lng: c.longitude } : null;

  return (
    <React.Fragment>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          onLoad={mapRef => {
            map = mapRef;
          }}
          zoom={zoom}
          center={center || undefined}
          onIdle={() => {
            // We use onIdle to ensure that we catch both drags and zoom events.
            // When they are completed (and the map becomes idle), if the latlng has
            // changed from its original value, we first an update event.

            if (!loaded || !interactive || !map || !onChange) {
              return;
            }

            const _center = map.getCenter();
            if (!_center) {
              return;
            }

            const newCenter = {
              latitude: _center.lat(),
              longitude: _center.lng(),
            };

            // Don't fire update event if no change from previous center.
            if (
              center &&
              newCenter.latitude === center.lat &&
              newCenter.longitude === center.lng
            ) {
              return;
            }

            onChange(newCenter);
          }}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            mapTypeId: mapTypeId || 'roadmap',
            gestureHandling: interactive ? 'greedy' : 'none',
          }}
        ></GoogleMap>
      ) : null}
      <div
        className={classNames(
          'absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center',
          !customMarker && 'pointer-events-none',
        )}
        style={!loaded ? { backgroundColor: 'rgba(255,255,255,0.7)' } : {}}
      >
        {!loaded || !isLoaded ? (
          <div className="text-cool-gray-700 text-sm">
            <img src={loader} className="mx-auto mb-4" />
          </div>
        ) : !hideMarker ? (
          customMarker || (
            <img
              src={mapMarker}
              style={{ width: 27, height: 43, marginTop: -43 }}
            />
          )
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default LocationMap;
