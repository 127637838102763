import './DateTime.css';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Datepicker from '@common/components/Datepicker';
import { DateTimeStepComponent } from '@common/types/ClaimWorkflow';

import { useMobileDetect } from '../../hooks';
import Dropdown from '../Dropdown';
import Label from './Label';
import {
  StepComponentControlsErrorProps, StepComponentFC, StepComponentSharedProps
} from './types/stepComponentTypes';

const USE_DATEPICKER_DESKTOP = true;

const CURRENT_YEAR = new Date().getFullYear();
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const fixIOSBug = (function (BST) {
  // BST should not be present as UTC time
  return new Date(BST).toISOString().slice(0, 16) === BST
    ? // if it is, it needs to be removed
      (d: Date) => new Date(d.getTime() + d.getTimezoneOffset() * 60000)
    : // otherwise can just be equivalent of toISOString
      (d: Date) => d;
})('2006-06-06T06:06');

const formatInputDateValue = (date: Date, dateOnly: boolean) => {
  if (dateOnly) {
    //if dateOnly just use the date part of the ISO string since UTC date should be accurate
    return date.toISOString().slice(0, -1).slice(0, 10);
  }

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(date.getTime() - tzoffset)
    .toISOString()
    .slice(0, -1); //remove Zulu timezone
  return localISOTime.slice(0, 16); //remove seconds
};

function parseISOString(s: string) {
  let b = s.split(/\D+/);
  let tempMonths = +b[1];
  return new Date(
    Date.UTC(+b[0], --tempMonths, +b[2], +b[3], +b[4], +b[5], +b[6]),
  );
}

type DateTimeProps = StepComponentSharedProps<
  DateTimeStepComponent,
  string | null
> &
  StepComponentControlsErrorProps;

const DateTime: StepComponentFC<DateTimeProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  error,
  showErrorMessages,
}) => {
  const { isMobile } = useMobileDetect();

  const dateOnly = step_component.mode === 'date';

  const innerClassName = `DateTime bg-white textbox p-2 px-4 border-solid border-2 rounded-lg focus:outline-none focus:shadow-outline w-full text-center${
    error ? ' Shake border-red-500' : ''
  }`;

  const v = primaryValue;
  const [date, setDate] = useState<Date | null>(null);
  const [desktopYear, setDesktopYear] = useState<number | null>(null);
  const [desktopMonth, setDesktopMonth] = useState<number | null>(null);
  const [desktopDay, setDesktopDay] = useState<number | null>(null);
  const [desktopHour, setDesktopHour] = useState<number | null>(null);
  const [desktopMinute, setDesktopMinute] = useState<number | null>(null);

  useEffect(() => {
    const useDate = (v && parseISOString(v)) || null;
    if (useDate) {
      setDate(useDate);
      setDesktopYear(useDate.getFullYear());
      setDesktopMonth(useDate.getMonth());
      setDesktopDay(useDate.getDate());
      setDesktopHour(useDate.getHours());
      setDesktopMinute(useDate.getMinutes());
    }
  }, [v]);

  useEffect(() => {
    const requirementsMet =
      desktopYear !== null &&
      desktopMonth !== null &&
      desktopDay !== null &&
      (!dateOnly ? desktopHour !== null && desktopMinute !== null : true);

    if (requirementsMet) {
      setDate(
        new Date(
          desktopYear!,
          desktopMonth!,
          desktopDay!,
          desktopHour!,
          desktopMinute!,
        ),
      );
    }
  }, [desktopYear, desktopMonth, desktopDay, desktopHour, desktopMinute]);

  useEffect(() => {
    date && updateValue(step_component.field, date.toISOString());
  }, [date]);

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value ? fixIOSBug(new Date(e.target.value)) : null);
  };

  const DateDropdown = USE_DATEPICKER_DESKTOP ? (
    <div>
      <Datepicker
        value={date}
        wide={!!step_component.wide_display}
        onChange={d => setDate(d)}
        showTimeSelect={!dateOnly}
        maxDate={step_component.date_mode === 'past' ? new Date() : undefined}
        minDate={step_component.date_mode === 'future' ? new Date() : undefined}
      />
    </div>
  ) : (
    <div>
      <div className={classNames('flex -mx-2 text-left')}>
        <div className="mx-2" style={{ flexBasis: '40%' }}>
          <Dropdown
            hasError={error ? true : false}
            placeholder="Month"
            value={desktopMonth !== null ? MONTHS[desktopMonth || 0] : ''}
            options={MONTHS}
            onChange={e => setDesktopMonth(MONTHS.indexOf(e.target.value))}
          />
        </div>
        <div className="mx-2" style={{ flexBasis: '20%' }}>
          <Dropdown
            hasError={error ? true : false}
            placeholder="Day"
            value={`${desktopDay || ''}`}
            options={new Array(
              desktopMonth === null
                ? 31
                : new Date(CURRENT_YEAR, desktopMonth || 0 + 1, 0).getDate(),
            )
              .fill(0)
              .map((_, i) => `${i + 1}`)}
            onChange={e => setDesktopDay(+e.target.value)}
          />
        </div>
        <div className="flex-1 mx-2">
          <Dropdown
            hasError={error ? true : false}
            placeholder="Year"
            value={`${desktopYear || ''}`}
            options={
              step_component.date_mode === 'past'
                ? new Array(100).fill(0).map((_, i) => `${CURRENT_YEAR - i}`)
                : step_component.date_mode === 'future'
                ? new Array(20).fill(0).map((_, i) => `${CURRENT_YEAR + i}`)
                : new Array(100)
                    .fill(0)
                    .map((_, i) => `${CURRENT_YEAR + 5 - i}`)
            }
            onChange={e => setDesktopYear(+e.target.value)}
          />
        </div>
      </div>
      {!dateOnly ? (
        <div className="mx-auto w-full mt-4 text-center">
          <div className="inline-block mr-4" style={{ width: 75 }}>
            <Dropdown
              hasError={error ? true : false}
              placeholder="Hour"
              value={((desktopHour || 0) % 12
                ? (desktopHour || 0) % 12
                : 12
              ).toString()}
              options={new Array(12)
                .fill(null)
                .map((_, i) => (i + 1).toString())}
              onChange={e => {
                setDesktopHour(
                  +e.target.value + ((desktopHour || 0) > 12 ? 12 : 0),
                );
              }}
            />
          </div>
          <div className="inline-block mr-4" style={{ width: 75 }}>
            <Dropdown
              hasError={error ? true : false}
              placeholder="Minute"
              value={
                ((desktopMinute || 0) < 10 ? '0' : '') +
                `${Math.floor((desktopMinute || 0) / 15) * 15}`
              }
              options={['00', '15', '30', '45']}
              onChange={e => setDesktopMinute(+e.target.value)}
            />
          </div>
          <div className="inline-block" style={{ width: 75 }}>
            <Dropdown
              hasError={error ? true : false}
              placeholder=""
              value={(desktopHour || 0) >= 12 ? 'PM' : 'AM'}
              options={['AM', 'PM']}
              onChange={e => {
                let dh = desktopHour || 0;
                setDesktopHour(
                  e.target.value === 'PM'
                    ? dh < 12
                      ? dh + 12
                      : dh
                    : dh >= 12
                    ? dh - 12
                    : dh,
                );
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );

  const Input = (
    <input
      type={dateOnly ? 'date' : 'datetime-local'}
      value={formatInputDateValue(date || new Date(), dateOnly)}
      onChange={onChangeDate}
      className={innerClassName}
    />
  );

  return (
    <div className={classNames(className, 'mt-4')}>
      {step_component.label ? <Label step_component={step_component} /> : null}
      {!isMobile ? DateDropdown : Input}
      {error && showErrorMessages ? (
        <div className="error-message">{error}</div>
      ) : null}
    </div>
  );
};

DateTime.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};

export default DateTime;
