import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import ReactConfetti from 'react-confetti';
import {
  WidgetComponentSharedProps,
  WidgetFC,
} from '../types/widgetComponentTypes';
import { ConfettiWidget } from '@common/types/ClaimWorkflow';

const Confetti: WidgetFC<WidgetComponentSharedProps<ConfettiWidget>> = ({
  widget,
  className,
}) => {
  const { width, height } = useWindowSize();
  return (
    <ReactConfetti
      width={width}
      height={height}
      style={{ position: 'fixed' }}
      recycle={false}
      numberOfPieces={300}
    />
  );
};

export default Confetti;
