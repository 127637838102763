import React from 'react';

export default function CoverageIcon({
  icon,
  className,
}: {
  icon: string;
  className?: string;
}) {
  return CoverageIconsRaw.hasOwnProperty(icon)
    ? React.cloneElement(
        CoverageIconsRaw[icon as keyof typeof CoverageIconsRaw],
        { className },
      )
    : null;
}
const CoverageIconsRaw = {
  bodily_injury_liability: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M20.5,37.8c0,0-3.8,0.8-6.7-2.1c-2.9-2.9-2.1-6.7-2.1-6.7l17-16.9c0,0,3.8-0.8,6.7,2.1c2.9,2.9,2.1,6.7,2.1,6.7
	L20.5,37.8z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M24.2,16.6l8.7,8.7"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,24.6l8.7,8.7"
      />
      <path
        fill="currentColor"
        d="M28.5,27.8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C27.4,27.3,27.9,27.8,28.5,27.8
	z"
      />
      <path
        fill="currentColor"
        d="M25.7,25c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1S25.1,25,25.7,25z"
      />
      <path
        fill="currentColor"
        d="M22.9,22.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C21.7,21.6,22.3,22.1,22.9,22.1
	z"
      />
      <path
        fill="currentColor"
        d="M24.6,28.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1S23.9,28.9,24.6,28.9z"
      />
      <path
        fill="currentColor"
        d="M21.7,26.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C20.6,25.6,21.1,26.1,21.7,26.1
	z"
      />
    </svg>
  ),
  property_damage_liability: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M26.8,39.6H14.1c-2.5,0-4.6-2.1-4.6-4.6V22.4l10.9-9.2l10.9,9.2V35C31.4,37.5,29.3,39.6,26.8,39.6z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.6,39.6v-8h5.8v8"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M25.9,16.9l1.1-3.8l1.8,3.3l5.4-1.9l-2.1,4.9l4.9,2.1l-5.5,3.9"
      />
    </svg>
  ),
  medical_payments: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,13h-3.1v7.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2V13h-3.1"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M18.3,25.5v3.9c0,4.2,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-4.9"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M33.3,24.4c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C29.7,22.8,31.3,24.4,33.3,24.4z"
      />
    </svg>
  ),
  uninsured_motorist: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <style type="text/css">
        {`.stxx0{clip-path:url(#SVGID_2_);}
	.stxx1{fill:none;stroke:currentColor;stroke-width:1.8286;stroke-miterlimit:10;}
	.stxx2{fill:currentColor;}`}
      </style>
      <g>
        <defs>
          <rect id="SVGID_1_" x="9" y="13.9" width="32" height="21.9" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <g className="stxx0">
          <path className="stxx1" d="M31.5,20.9l4.6,4.5" />
          <path className="stxx1" d="M31.5,25.5l4.6-4.5" />
          <path
            className="stxx2"
            d="M21.5,23c0.1-1.6-1.3-2.9-3-2.9c-1.7,0-3.1,1.3-2.9,2.9c0,0.3,0.3,2,0.3,2c0.2,1.4,1.2,2.6,2.6,2.6
			c1.4,0,2.4-1.2,2.6-2.6C21.2,25,21.5,23.4,21.5,23z"
          />
          <path
            className="stxx2"
            d="M25.9,35H11.2l0.2-3.2c0.2-1.3,1.2-2,2.3-2.4l4.7-0.7l4.7,0.7c1.3,0.2,2.3,1,2.4,2.4L25.9,35z"
          />
          <path
            className="stxx1"
            d="M33.9,29.5c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3c-3.5,0-6.3,2.8-6.3,6.3
			C27.6,26.7,30.4,29.5,33.9,29.5z"
          />
          <path
            className="stxx1"
            d="M35.9,28.8v1.3c0,2.7-2,4.9-4.5,4.9H14.2c-2.5,0-4.5-2.2-4.5-4.9V19.5c0-2.7,2-4.9,4.5-4.9h17.1
			c1.8,0,3.4,1.2,4.1,2.9"
          />
        </g>
      </g>
    </svg>
  ),
  collision: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M18.7,31.6h8"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M15.5,34.7c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2C12.3,33.3,13.7,34.7,15.5,34.7
	z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M29.8,34.7c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2C26.6,33.3,28.1,34.7,29.8,34.7
	z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M29.2,23.3h-3.4c-0.7,0-1.3-0.7-1.1-1.4l1-3.7"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M12.3,31.6h-3l-2.2-3.2c0,0,2.5-4.9,6.5-4.9c0,0,0.6-5.7,8.3-5.7s7.7,5.7,7.7,5.7c5.4,0,7.5,2.3,8.4,4.4
	c0.6,1.5-0.4,3.2-2.1,3.2h-3.1"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M15.3,20.1h1.5c0.7,0,1.3,0.6,1.3,1.3V22c0,0.7-0.6,1.3-1.3,1.3h-3.2"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M30.8,20.8V17l2.6,2.5l4.5-3.2l-0.6,5.1l5.1,0.6l-2.6,3.2"
      />
    </svg>
  ),
  roadside_assistance: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M27.4,27.2H10.2V31l4,1.3"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M35.7,32.6h1c1.6,0,2.8-1.4,2.6-3l-1-11.6h-9.2l-0.7,9.3h-0.7l-6.2-13.1h-2l2,12.6"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M20.7,32.3H29"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.4,35.6c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3C14.1,34.2,15.6,35.6,17.4,35.6
	z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M32.3,35.6c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3C29,34.2,30.5,35.6,32.3,35.6z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M38.9,24.4h-5v-6.6"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.5,14v3.4c0,1.7-1.8,2.8-3.3,1.9"
      />
    </svg>
  ),
  rental_car: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M12.1,18.7H9.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6H11"
      />
      <path
        fill="currentColor"
        d="M13.5,30.1h-2.4c-0.7,0-1.3-0.6-1.3-1.3v-0.3c0-0.8,0.7-1.5,1.5-1.3l2.4,0.3c0.7,0.1,1.2,0.6,1.2,1.3
	C14.8,29.4,14.2,30.1,13.5,30.1z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.7,28.5h9.8"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.1,17.4h11.4l1.2,5.1H15L16.1,17.4z"
      />
      <path
        fill="currentColor"
        d="M35.4,34.6v1.1c0,0.1-0.1,0.3-0.3,0.3h-2.7c-0.2,0-0.3-0.2-0.3-0.2l-0.2-1.2c-1.5,0-2.7-1-3-2.4H12.1l-0.6,3.5
	c0,0.1-0.1,0.3-0.3,0.3H8.5c-0.1,0-0.3-0.1-0.3-0.3V29c0-1.2,0.4-2.3,1.1-3.2l2.6-3.5l1.7-5.2c0.5-1.4,1.6-2.4,3-2.7
	c1.3-0.2,3.1-0.4,5.2-0.4c1.6,0,3.3,0.2,5.1,0.5c0.9,0.2,1.8,0.7,2.4,1.5c0.3-0.6,0.9-1.1,1.5-1.4c-0.9-1.1-2.1-1.9-3.5-2.1
	c-1.9-0.4-3.7-0.5-5.4-0.5c-2.2,0-4.2,0.3-5.5,0.5c-2.1,0.4-3.9,1.9-4.6,4l-1.6,4.8l-2.4,3.2c-1,1.3-1.5,2.8-1.5,4.4v6.8
	c0,1.3,1,2.3,2.3,2.3h2.7c1.1,0,2-0.8,2.3-1.9l0.3-1.9h16l0.3,1.9c0.2,1.1,1.2,1.9,2.3,1.9h2.7c1.3,0,2.3-1,2.3-2.3v-1.1H35.4z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M38.8,30.8h-6.4v-2.9c0-0.9,0.4-1.8,1.2-2.4l2-1.5l2,1.5c0.8,0.6,1.2,1.4,1.2,2.4V30.8z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M32.4,17.4h6.4v2.9c0,0.9-0.4,1.8-1.2,2.4l-2,1.5l-2-1.5c-0.8-0.6-1.2-1.4-1.2-2.4
	C32.4,20.3,32.4,17.4,32.4,17.4z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M30.9,30.8h9.3"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M30.9,17.4h9.3"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M32.4,28.7l3.2-0.6l3.2,0.6"
      />
      <path
        fill="currentColor"
        d="M32.4,28.5l3.2-0.7l3.2,0.7l0.1,1.6h-6.5L32.4,28.5z"
      />
    </svg>
  ),
  uninsured_motorist_damage: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <style type="text/css">{`.st0{fill:currentColor;}`}</style>
      <g>
        <rect x="14.1" y="26.2" className="st0" width="4.9" height="1.9" />
        <rect x="11.3" y="30.1" className="st0" width="8.4" height="1.9" />
        <rect x="10.6" y="22.3" className="st0" width="11.9" height="1.9" />
        <path
          className="st0"
          d="M46.6,27.3c-2.1,0-3.9,1.5-4.3,3.5h-7.1c-0.4-2-2.2-3.5-4.3-3.5s-3.9,1.5-4.3,3.5h-1.9l-1.8-2.7
		c2.6-4.4,5.1-4.4,6.8-4.6h2.7c1.3,0,2.3-1.1,2.3-2.4v-0.8c0-1-0.6-1.8-1.4-2.2c1.1-0.6,2.7-1,4.7-1c1.2,0,2.2,0.2,3,0.4l-0.8,3.2
		c-0.2,0.6,0,1.3,0.4,1.9c0.4,0.5,1.1,0.9,1.7,0.9c4.7,0,5.9,0.1,7.4,0.4c0-0.7-0.1-1.3-0.1-2c-0.7-0.1-1.5-0.2-2.3-0.3
		c-0.4-2-2-6.4-9.2-6.4c-7.3,0-9.3,4.5-9.9,6.4c-4.4,0.6-7,5.8-7.1,6l-0.2,0.5l3,4.5h3c0.4,2,2.2,3.5,4.3,3.5s3.9-1.5,4.3-3.5h7.1
		c0.4,2,2.2,3.5,4.3,3.5c0.1,0,0.1,0,0.2,0c0.4-0.7,0.7-1.4,1-2.2c-0.4,0.2-0.7,0.3-1.2,0.3c-1.4,0-2.6-1.2-2.6-2.6
		c0-1.4,1.1-2.6,2.6-2.6c1,0,1.9,0.6,2.3,1.4c0.2-0.8,0.3-1.6,0.5-2.4C48.6,27.7,47.6,27.3,46.6,27.3z M42.7,18.4
		c1.6,1,2.2,2.3,2.5,3.3h-3c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2L42.7,18.4z M31,19.9h1.3c0.2,0,0.5,0.2,0.5,0.5v0.8
		c0,0.3-0.2,0.5-0.5,0.5H30C30.2,21.1,30.5,20.5,31,19.9z M30.9,34.4c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.1-2.6,2.6-2.6s2.6,1.2,2.6,2.6
		C33.4,33.2,32.3,34.4,30.9,34.4z"
        />
      </g>
    </svg>
  ),
  comprehensive: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <style type="text/css">
        {`.comp_1{fill:none;stroke:currentColor;stroke-width:1.8462;stroke-miterlimit:10;}
	.comp_2{fill:currentColor;}`}
      </style>
      <g>
        <defs>
          <rect id="SVGID_1_" x="13" y="11.3" width="24" height="26.8" />
        </defs>
        <g className="comp_0">
          <path
            className="comp_1"
            d="M25,37.2c0,0-11.2-3-11.2-14v-9.1c4.4,0.2,7.9-0.6,11.2-1.9c3.2,1.3,6.8,2.2,11.2,1.9v9.1
			C36.2,34.2,25,37.2,25,37.2z"
          />
          <path
            className="comp_2"
            d="M25.7,22.5l0.9-5.6l-6,7.5h3.8L23.4,30l6-7.5H25.7z"
          />
        </g>
      </g>
    </svg>
  ),
  basic_economic_loss: (
    <svg x="0px" y="0px" viewBox="0 0 50.98 42.05">
      <defs>
        <style>{`.cls-1,.cls-2{fill:none;stroke:currentColor;stroke-width:2px;}.cls-1{stroke-miterlimit:10;}.cls-2{stroke-linejoin:round;}`}</style>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M35.61,37.8H16.48l1.16-3.3A5.62,5.62,0,0,1,23,30.73h6.19a5.62,5.62,0,0,1,5.31,3.77Z"
        />
        <path className="cls-2" d="M18.09,24.72a5.1,5.1,0,1,1-10.19,0Z" />
        <path className="cls-2" d="M34,17.19a5.1,5.1,0,0,0,10.19,0Z" />
        <polyline className="cls-2" points="7.9 24.72 13.27 15.6 18.09 24.72" />
        <polyline className="cls-2" points="44.19 17.19 38.83 8.07 34 17.19" />
        <path className="cls-1" d="M11.85,14.81c7-1.84,7.83-8.31,12.41-5.13" />
        <path className="cls-1" d="M41.09,7c-7,1.88-10.92-3.33-13.32,1.7" />
        <path
          className="cls-1"
          d="M24.53,30.35V7.07a1.51,1.51,0,0,1,1.52-1.51h0a1.51,1.51,0,0,1,1.51,1.51V30.35"
        />
      </g>
    </svg>
  ),
  pip: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,13h-3.1v7.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2V13h-3.1"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M18.3,25.5v3.9c0,4.2,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-4.9"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M33.3,24.4c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C29.7,22.8,31.3,24.4,33.3,24.4z"
      />
    </svg>
  ),
};
