import React from 'react';

import accident from '../images/icons/accident.svg';
import anvil_google from '../images/icons/anvil-google.png';
import anvil_mac from '../images/icons/anvil-mac.png';
import anvil_win from '../images/icons/anvil-win.png';
import fence_mac from '../images/icons/fence-mac.png';
import fence_win from '../images/icons/fence-win.png';
import pickuptruck from '../images/icons/pickup-truck.png';
import theft from '../images/icons/theft.svg';
import { isWindows } from '../utils';

const IMAGES: Record<
  string,
  string | { mac: string; win: string; google?: string }
> = {
  theft,
  accident,
  pickuptruck,
  fence: { mac: fence_mac, win: fence_win },
  anvil: { mac: anvil_mac, win: anvil_win, google: anvil_google },
};

interface IconProps {
  icon?: string;
  text?: string;
  small?: boolean;
}

const Icon: React.FC<IconProps> = ({ icon, text, small }) => {
  if (text) {
    return (
      <div
        className="h-20 flex flex-col justify-center"
        style={{ minWidth: 60 }}
      >
        {/* text-3xl text-4xl text-5xl text-6xl */}
        <div className={`text-${Math.max(6 - text.length, 3)}xl leading-none`}>
          {text}
        </div>
      </div>
    );
  } else if (icon) {
    const iconPayload = IMAGES[icon];
    let iconSrc;

    if (typeof iconPayload === 'string') {
      iconSrc = iconPayload;
    } else {
      iconSrc = isWindows() ? iconPayload.win : iconPayload.mac;
    }

    return (
      <img
        className={small ? 'w-6 h-6' : 'w-20 h-20 p-5 mx-auto'}
        src={iconSrc}
      />
    );
  }

  return null;
};

export default Icon;
