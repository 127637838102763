import { scaleLinear, scaleLog } from 'd3-scale';

import * as turf from '@turf/turf';

export type Position = [number, number];

interface Keyframe {
  percent: number;
  position: Position; // [lat, lng]
  speed: number; // miles/hr
}

interface Party {
  path: Position[]; // [lat,lng][]
  stationary_position?: Position;
  stationary_angle?: number;
  is_leading?: boolean;
  keyframes: Keyframe[];
}

interface Entry {
  party: Party;
  line?: turf.Feature<turf.LineString>;
  distance_meters?: number;
  duration_seconds?: number;
  steps?: {
    time: number;
    position: [number, number];
    speed: number;
    progress: number;
  }[];
  scale?: any;
}

interface Tick {
  time: number; // seconds
  entries: {
    position: Position;
    speed: number;
    speed_mph: number;
    progress: number;
  }[];
}

export const NEEDS_LEADING_VEHICLE = 'NEEDS_LEADING_VEHICLE';

export const MINIMUM_PATH_LENGTH_METERS = 10; // meters

const METERS_PER_SECOND_PER_MILE_PER_HOUR = 0.44704;

const PATH_RESOLUTION = 0.5; // 0.5 meter chunks
const TIME_RESOLUTION = 0.01; // 10ms second chunks

// If any two points on paths are within this threshold, consider them as intersecting
// right away.
const INSTANT_INTERSECT_THRESHOLD_METERS = 3; // meters

/**
 * Compute bearing between two points.
 */
const computeBearing = (a: Position, b: Position) => {
  return turf.bearing(turf.point(a), turf.point(b));
};

/**
 * Given a set of normalized reconstructed Ticks, compute statistics
 * about the collision.
 */
function computeStatistics({
  ticks,
  parties,
}: {
  ticks: Tick[];
  parties: Party[];
}) {
  const lastTick = ticks[ticks.length - 1];
  const nextToLastTick = ticks[ticks.length - 2];

  const bearings = [0, 1].map(i =>
    typeof parties[i].stationary_angle !== 'undefined'
      ? parties[i].stationary_angle!
      : computeBearing(
          nextToLastTick.entries[i].position,
          lastTick.entries[i].position,
        ),
  );

  const raw_angle_degrees = bearings[0] - bearings[1];
  const raw_angle_radians = raw_angle_degrees * (Math.PI / 180);

  const impact_angle_degrees = Math.min(
    Math.abs(raw_angle_degrees),
    360 - Math.abs(raw_angle_degrees),
  );

  // Set initial masses and velocities
  const m1 = 1500; //kg
  const m2 = 1500; //kg
  const v1_x = lastTick.entries[0].speed; //m/s
  const v2_x = lastTick.entries[1].speed * Math.cos(raw_angle_radians); //m/s
  const v1_y = 0; //m/s
  const v2_y = lastTick.entries[1].speed * Math.sin(raw_angle_radians); //m/s

  // By conservation of momentum and assuming perfectly inelastic collision
  const mf = m1 + m2; //kg of combined mass
  const vf_x = (m1 * v1_x + m2 * v2_x) / mf; //m/s of combined mass
  const vf_y = (m1 * v1_y + m2 * v2_y) / mf; //m/s of combined mass

  // New momentums (should be conserved)
  const p1_x = m1 * v1_x;
  const p1_y = m1 * v1_y;
  const p2_x = m2 * v2_x;
  const p2_y = m2 * v2_y;
  const p1 = Math.sqrt(p1_x ** 2 + p1_y ** 2);
  const p2 = Math.sqrt(p2_x ** 2 + p2_y ** 2);
  const pf = Math.sqrt((mf * vf_x) ** 2 + (mf * vf_y) ** 2);

  // Compute kinetic energy before and after the collision (of individuals vs combined)
  const k1 = 0.5 * m1 * (v1_x ** 2 + v1_y ** 2);
  const k2 = 0.5 * m2 * (v2_x ** 2 + v2_y ** 2);
  const kf = 0.5 * mf * (vf_x ** 2 + vf_y ** 2);
  const delta_ke = kf - (k1 + k2);

  // "Impact energy" is the change in kinetic energy of the system
  const impact_energy = Math.abs(delta_ke); // J
  const impact_data = {
    raw_angle_degrees,
    raw_angle_radians,
    m1,
    m2,
    v1_x,
    v1_y,
    v2_x,
    v2_y,
    mf,
    vf_x,
    vf_y,
    p1,
    p2,
    pf,
    k1,
    k2,
    kf,
    delta_ke,
  };

  const relative_speed = Math.sqrt((v1_x - v2_x) ** 2 + (v1_y - v2_y) ** 2);
  const relative_speed_mph =
    relative_speed * (1 / METERS_PER_SECOND_PER_MILE_PER_HOUR);

  return {
    /** Relative angle between the two vehicles at the collision point. Between 0-180 degrees. */
    impact_angle_degrees,
    /** Relative speed between the two vehicles, i.e. ||v_a - v_b||. In meters/second. */
    relative_speed,
    relative_speed_mph,
    /** Maximum impact energy (i.e. kinetic energy loss) of the collision */
    impact_energy,
    impact_data,
  };
}

/**
 * Given 2 paths, identify the nearest pair of points on them.
 */
export function findNearestPoints(pathA: Position[], pathB: Position[]) {
  const hasStationaryVehicle = pathA.length === 1 || pathB.length === 1;

  // Step pairwise through every edge in each line. Begin at the *end* of the paths,
  // to try to find the last point they overlap. This way if the cars are going the same
  // direction, we truncate at the last stop (e.g. driving forward and then rear-ended)
  const intersects = [];
  if (!hasStationaryVehicle) {
    for (let a_i = pathA.length - 1; a_i > 0; a_i--) {
      for (let b_i = pathB.length - 1; b_i > 0; b_i--) {
        const aLine = turf.lineString([pathA[a_i], pathA[a_i - 1]]),
          bLine = turf.lineString([pathB[b_i], pathB[b_i - 1]]),
          intersect = turf.lineIntersect(aLine, bLine).features?.[0];
        if (intersect) {
          intersects.push({ entries: [a_i, b_i] });
        }
      }
    }
  }

  const nearest = {
    entries: [-1, -1],
    distance: Number.POSITIVE_INFINITY,
  };

  const checkPoints = (a_i: number, b_i: number) => {
    const dist = turf.distance(pathA[a_i], pathB[b_i], {
      units: 'meters',
    });
    if (dist < nearest.distance) {
      nearest.entries[0] = a_i;
      nearest.entries[1] = b_i;
      nearest.distance = dist;
      if (dist < INSTANT_INTERSECT_THRESHOLD_METERS) {
        // We are "close enough", stop immediately
        return true;
      }
    }
    return false;
  };

  // Step through points pairwise and find the nearest ones.
  const shouldBeginAtEnd = hasStationaryVehicle;
  if (!shouldBeginAtEnd) {
    outer: for (let a_i = pathA.length - 1; a_i >= 0; a_i--) {
      for (let b_i = pathB.length - 1; b_i >= 0; b_i--) {
        const shouldTerminate = checkPoints(a_i, b_i);
        if (shouldTerminate) {
          break outer;
        }
      }
    }
  } else {
    outer: for (let a_i = 0; a_i < pathA.length; a_i++) {
      for (let b_i = 0; b_i < pathB.length; b_i++) {
        const shouldTerminate = checkPoints(a_i, b_i);
        if (shouldTerminate) {
          break outer;
        }
      }
    }
  }

  // If a true intersect is within threshold of the point itself, prefer that intersect instead.
  for (const intersect of intersects) {
    const distA = turf.distance(
      pathA[nearest.entries[0]],
      pathA[intersect.entries[0]],
      { units: 'meters' },
    );
    const distB = turf.distance(
      pathB[nearest.entries[1]],
      pathB[intersect.entries[1]],
      { units: 'meters' },
    );
    if (
      distA < INSTANT_INTERSECT_THRESHOLD_METERS ||
      distB < INSTANT_INTERSECT_THRESHOLD_METERS
    ) {
      nearest.entries = intersect.entries;
      nearest.distance = 0;
      break;
    }
  }

  return nearest;
}

/**
 * Determine if 2 paths are "overlapping" near the end of their run. This is used
 * particularly to identify the special case scenario of one car driving behind
 * another car before then colliding.
 */
export function checkOverlap(pathA: Position[], pathB: Position[]) {
  const OVERLAP_THRESHOLD_METERS = 1; // meters
  const OVERLAP_TOTAL_SCORE_THRESHOLD = 0.2; // score value
  const OVERLAP_MODULATOR = (percent: number) => percent;

  const overlaps = [];
  for (let a_i = pathA.length - 1; a_i > 0; a_i--) {
    for (let b_i = pathB.length - 1; b_i > 0; b_i--) {
      const dist = turf.distance(pathA[a_i], pathB[b_i], {
        units: 'meters',
      });
      const pct_a = a_i / pathA.length;
      const pct_b = b_i / pathB.length;
      if (dist < OVERLAP_THRESHOLD_METERS) {
        overlaps.push(OVERLAP_MODULATOR((pct_a + pct_b) / 2));
      }
    }
  }
  const maxOverlapScore = Math.max(pathA.length, pathB.length) * 1;
  const overlapScore =
    overlaps.reduce((t, overlap) => t + overlap, 0) / maxOverlapScore;
  return overlapScore > OVERLAP_TOTAL_SCORE_THRESHOLD;
}

function generateSteps({
  path,
  keyframes,
}: {
  path: Position[];
  keyframes: Keyframe[];
}) {
  const line = turf.lineString(path);
  const distance_meters = turf.length(line, { units: 'meters' });

  const scale = scaleLinear()
    .domain(keyframes.map(kf => kf.percent))
    .range(
      keyframes.map(
        kf => (kf.speed || 1) * METERS_PER_SECOND_PER_MILE_PER_HOUR,
      ),
    );

  let steps = [
    {
      time: 0,
      position: path[0],
      speed: 0,
      progress: 0,
    },
  ];

  for (
    let step = PATH_RESOLUTION / 2; // midpoint riemann
    step < distance_meters;
    step += PATH_RESOLUTION
  ) {
    const pct = step / distance_meters;
    const speed_meters_per_second = Math.max(scale(pct), 0);

    const pos = turf.along(line, Math.min(step, distance_meters), {
      units: 'meters',
    }).geometry.coordinates;

    // Use speed from first point
    if (steps.length === 1) {
      steps[0].speed = speed_meters_per_second;
    }

    if (!speed_meters_per_second) continue;

    steps.push({
      time:
        steps[steps.length - 1].time +
        PATH_RESOLUTION / speed_meters_per_second,
      speed: speed_meters_per_second,
      position: pos as [number, number],
      progress: pct,
    });
  }

  const duration_seconds = steps[steps.length - 1].time;

  return { line, steps, distance_meters, duration_seconds };
}

/**
 * Given 2 parties with paths and keyframed speeds, interpolate the data to generate
 * time-accurate trajectories across the paths.
 */
export function generateTrajectories(parties: Party[]) {
  const entries: Entry[] = parties.map(party => ({ party }));

  for (const entry of entries) {
    // If stationary_position, generate a "path" from this stationary spot.
    if (entry.party.stationary_position) {
      entry.party.path = [
        entry.party.stationary_position,
        entry.party.stationary_position,
      ];
    }

    const res = generateSteps({
      path: entry.party.path,
      keyframes: entry.party.keyframes,
    });
    entry.line = res.line;
    entry.steps = res.steps;
    entry.distance_meters = res.distance_meters;
    entry.duration_seconds = res.duration_seconds;
  }

  if (entries.some(e => !e.steps)) {
    throw new Error('Missing step');
  }

  const nearest = findNearestPoints(
    entries[0].steps?.map(e => e.position) || [],
    entries[1].steps?.map(e => e.position) || [],
  );

  let collision_a = entries[0].steps![nearest.entries[0]],
    collision_b = entries[1].steps![nearest.entries[1]];

  // Whichever collision point happens later in the items relative timeline
  // is going to be our first timeline.
  const hasLeadingVehicle = entries.some(e => e.party.is_leading);
  let aIsFirst = hasLeadingVehicle
    ? !!entries[0].party.is_leading
    : collision_a.time > collision_b.time;
  let first_entry = aIsFirst ? entries[0] : entries[1];
  let second_entry = aIsFirst ? entries[1] : entries[0];
  let first_collision = aIsFirst ? collision_a : collision_b;
  let second_collision = aIsFirst ? collision_b : collision_a;

  if (
    typeof first_entry.duration_seconds === 'undefined' ||
    typeof second_entry.duration_seconds === 'undefined'
  ) {
    throw new Error('missing duration');
  }

  let ticks: Tick[] = [];

  const secondTimeAfterCollision =
    second_entry.duration_seconds - second_collision.time;
  const totalDuration = Math.max(
    first_entry.duration_seconds,
    first_collision.time + secondTimeAfterCollision,
  );

  const isOverlapScenario = checkOverlap(
    first_entry.steps?.map(s => s.position) || [],
    second_entry.steps?.map(s => s.position) || [],
  );

  // We need at least one "leading" vehicle.
  if (isOverlapScenario && !hasLeadingVehicle) {
    throw new Error(NEEDS_LEADING_VEHICLE);
  }

  if (isOverlapScenario) {
    const BAD_OVERLAP_DISTANCE_METERS = 6.5;
    const second_path = (second_entry.steps || []).map(s => s.position);
    const isBad = () => {
      return (
        turf.distance(
          second_path[second_path.length - 1],
          first_collision.position,
          { units: 'meters' },
        ) < BAD_OVERLAP_DISTANCE_METERS
      );
    };
    while (isBad()) {
      second_path.pop();
    }
    const res = generateSteps({
      path: second_path,
      keyframes: second_entry.party.keyframes,
    });
    second_entry.line = res.line;
    second_entry.steps = res.steps;
    second_entry.distance_meters = res.distance_meters;
    second_entry.duration_seconds = res.duration_seconds;
    const new_collision_point =
      second_entry.steps[second_entry.steps.length - 1].position;
    collision_a = { ...collision_a, position: new_collision_point };
    collision_b = { ...collision_b, position: new_collision_point };
  }

  const second_start_time =
    second_entry.duration_seconds === 0
      ? 0
      : first_collision.time -
        (second_entry.duration_seconds - secondTimeAfterCollision);

  for (const e of entries) {
    if (!e.steps) {
      throw new Error('missing steps');
    }
    const times = e.steps.map(s => s.time);
    const lat = scaleLinear()
      .domain(times)
      .range(e.steps.map(s => s.position[0]))
      .clamp(true);
    const lng = scaleLinear()
      .domain(times)
      .range(e.steps.map(s => s.position[1]))
      .clamp(true);
    const speed = scaleLinear()
      .domain(times)
      .range(e.steps.map(s => s.speed))
      .clamp(true);
    const progress = scaleLinear()
      .domain(times)
      .range(e.steps.map(s => s.progress))
      .clamp(true);
    e.scale = { lat, lng, speed, progress };
  }

  for (let t = 0; t < totalDuration; t += TIME_RESOLUTION) {
    let entries = [first_entry, second_entry].map((e, i) => {
      const isSecondEntry = i === 1;
      if (isSecondEntry && t < second_start_time) {
        return {
          position: [0, 0] as Position, // currently, don't render the car at all - e.steps![0].position,
          speed: -1,
          speed_mph: -1,
          progress: 0,
        };
      }

      const isStationary = !!e.party.stationary_position;
      const scaledT = isSecondEntry ? t - second_start_time : t;
      const ended = isStationary || scaledT > (e.duration_seconds || 0);
      const speed = ended ? 0 : (e.scale.speed(scaledT) as number);
      return {
        position: isStationary
          ? e.party.stationary_position!
          : ([e.scale.lat(scaledT), e.scale.lng(scaledT)] as Position),
        speed,
        speed_mph: speed * (1 / METERS_PER_SECOND_PER_MILE_PER_HOUR),
        progress: isStationary ? 1 : e.scale.progress(scaledT),
      };
    });
    if (!aIsFirst) entries.reverse();

    ticks.push({
      time: t,
      entries,
    });
  }

  let collision_avg_position = turf.midpoint(
    collision_a.position,
    collision_b.position,
  ).geometry.coordinates;

  const statistics = computeStatistics({ ticks, parties });

  return {
    entries,
    ticks,
    duration: totalDuration,
    collision_avg_position,
    collision_a,
    collision_b,
    statistics,
  };
}
