import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { SelectOrCreateStepComponent } from '@common/types/ClaimWorkflow';

import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';

interface SelectOrCreateProps
  extends StepComponentSharedProps<SelectOrCreateStepComponent, any> {
  forceSubmit: () => void;
}
const SelectOrCreate: StepComponentFC<SelectOrCreateProps> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
  primaryValue,
}) => {
  const isUnknown = primaryValue?.__unknown__ === true;
  const isOther =
    primaryValue &&
    !isUnknown &&
    step_component.options.indexOf(primaryValue) === -1;

  return (
    <div className={className}>
      {step_component.options
        .concat([
          { label: step_component.create.label || 'Add new person', new: true },
        ])
        .concat(
          step_component.create.include_empty_option
            ? [{ label: "I don't know", __unknown__: true }]
            : [],
        )
        .map(option => {
          const selected =
            primaryValue === option ||
            (option.new && isOther) ||
            (option.__unknown__ && isUnknown);

          return (
            <label
              key={option.label.toString()}
              className={classNames(
                'flex border-solid border-2 rounded-lg px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out',
                selected && 'bg-blue-100 border-blue-300',
                option.new && selected ? 'py-3' : 'py-2',
                'my-4',
              )}
            >
              <input
                type="checkbox"
                className="form-radio focus:shadow-none focus:border-gray-300 cursor-pointer h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                checked={selected}
                onChange={() => {
                  updateValue(
                    step_component.field,
                    option.__unknown__
                      ? {
                          ...option,
                          ...step_component.create.base,
                        }
                      : option,
                  );
                }}
              />
              <div className="flex-1 text-cool-gray-700 ml-4 text-left">
                {option.label}
                {option.new && selected ? (
                  <div>
                    {step_component.create.fields.map((f, i) => {
                      return (
                        <div key={f.key} className="flex items-center mt-1">
                          <input
                            autoFocus={i === 0}
                            type="text"
                            className="textbox"
                            placeholder={f.label}
                            value={primaryValue?.[f.key] || ''}
                            onChange={e => {
                              updateValue(step_component.field, {
                                ...step_component.create.base,
                                ...option,
                                [f.key]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </label>
          );
        })}
    </div>
  );
};

export default SelectOrCreate;
