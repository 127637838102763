import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/browser';

import App from './App';
import config from './config';

smoothscroll.polyfill();

const canHover = !matchMedia('(hover: none)').matches;
if (canHover) {
  document.body.classList.add('can-hover');
}

// Force redirect to another domain. This is used if a deployment goes live
// before carrier is actually ready, for example.
if (process.env.REACT_APP_FORCE_REDIRECT) {
  window.location.href = process.env.REACT_APP_FORCE_REDIRECT;
}

// Cache the initial search for later. This is used in useWorkflowTelemtry, to extract
// UTM parameters later when sending tracking events.
window.__assuredInitialSearch = window.location.search;

// On chunk load failures, forcibly reload the page. This should be pretty
// much transparent to the user, because chunks are only loaded on navigation
// events, so it just seems like a "weird" navigation event.
window.addEventListener('error', e => {
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    Localize?: {
      getLanguage: () => string;
      setLanguage: (l: string) => void;
      on: (e: string, f: (d: any) => void) => void;
      off: (e: string, f: (d: any) => void) => void;
    };
  }
}

Sentry.init({
  dsn: config.sentryDsn,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
