import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { geolocationPermissionHasBeenGranted } from './useGeolocation';

// So we can inspect the state of the initial search query to extract UTM parameters. Necessary in case
// useWorkflowTelemtry mounts later on, after the initial search query has been manipulated.
declare global {
  interface Window {
    __assuredInitialSearch?: string;
  }
}

const TELEMETRY_INTERVAL_MS = 1000 * 5;

const getAdditionalData = () => {
  let utmData: {
    utmSource: string | null;
    utmMedium: string | null;
    utmCampaign: string | null;
    utmTerm: string | null;
    utmContent: string | null;
  } = {
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
  };

  try {
    const params = new URLSearchParams(window.__assuredInitialSearch);
    utmData = {
      utmSource: params.get('utm_source'),
      utmMedium: params.get('utm_medium'),
      utmCampaign: params.get('utm_campaign'),
      utmTerm: params.get('utm_term'),
      utmContent: params.get('utm_content'),
    };
  } catch (e) {
    // unsupported
  }

  let timezone: string | null = null;
  try {
    const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = systemTz;
  } catch (e) {
    // unsupported
  }

  return { ...utmData, timezone };
};

interface SaveWorkflowTelemetryVars {
  workflow: string;
  payload: {
    serializedLocation?: string;
  } & ReturnType<typeof getAdditionalData>;
}

interface WorkflowTelemetryProps {
  workflowId: string;
}

const SAVE_WORKFLOW_TELEMETRY = gql`
  mutation SaveWorkflowTelemetry(
    $workflow: ID!
    $payload: WorkflowTelemetryPayload!
  ) {
    saveWorkflowTelemetry(workflow: $workflow, payload: $payload)
  }
`;

export default function useWorkflowTelemetry({
  workflowId,
}: WorkflowTelemetryProps) {
  const [saveWorkflowTelemetry] = useMutation<{}, SaveWorkflowTelemetryVars>(
    SAVE_WORKFLOW_TELEMETRY,
  );

  const saveTelemetry = ({
    serializedLocation,
  }: {
    serializedLocation?: string;
  } = {}) => {
    saveWorkflowTelemetry({
      variables: {
        workflow: workflowId,
        payload: {
          serializedLocation,
          ...getAdditionalData(),
        },
      },
    });
  };

  const onEvent: PositionCallback = event => {
    const location = {
      accuracy: event.coords.accuracy,
      altitude: event.coords.altitude,
      altitudeAccuracy: event.coords.altitudeAccuracy,
      heading: event.coords.heading,
      latitude: event.coords.latitude,
      longitude: event.coords.longitude,
      speed: event.coords.speed,
      timestamp: event.timestamp,
    };
    saveTelemetry({ serializedLocation: JSON.stringify(location) });
  };

  const onEventError: PositionErrorCallback = error => {
    console.error(error);
  };

  useEffect(() => {
    let watchId: number, pollId: number;

    async function _poll() {
      if (geolocationPermissionHasBeenGranted) {
        window.clearInterval(pollId);
        watchId = navigator.geolocation.watchPosition(onEvent, onEventError);
      }
    }
    _poll();
    saveTelemetry();

    pollId = window.setInterval(() => _poll(), TELEMETRY_INTERVAL_MS);

    return () => {
      window.clearInterval(pollId);
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);
}
