import React, { useContext, useEffect, useState } from 'react';

import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import {
  GetIdTokenClaimsOptions, GetTokenWithPopupOptions, IdToken, LogoutOptions, RedirectLoginOptions
} from '@auth0/auth0-spa-js/dist/typings';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

import config from '../config';
import { getLocalStorage, getSessionStorage, setLocalStorage } from '../utils';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let _magicModeToken: string | null = null;
let _adminModeToken: string | null = null;

let _auth0Client: Auth0Client | null;
let pendingRequests: {
  resolve: (value?: string) => void;
  reject: (value?: string) => void;
}[] = [];
export const getTokenSilently = async (): Promise<string | undefined> => {
  if (_magicModeToken) {
    return `magic_${_magicModeToken}`;
  }
  if (_adminModeToken) {
    return `admin_${_adminModeToken}`;
  }
  if (config.publicAccessMode) {
    return undefined;
  }
  if (_auth0Client) {
    return _auth0Client.getTokenSilently().then(t => `user_${t}`);
  } else {
    return new Promise((resolve, reject) => {
      pendingRequests.push({ resolve, reject });
    });
  }
};

const getUrlToken = (storageKey: string, paramKey: string) => {
  const params = new URLSearchParams(window.location.search);
  let token = params.get(paramKey);
  if (token) {
    setLocalStorage(storageKey, token);
    return token;
  }
  if (getLocalStorage(storageKey)) {
    return getLocalStorage(storageKey);
  }
  if (getSessionStorage(storageKey)) {
    return getSessionStorage(storageKey);
  }
  return null;
};

interface Auth0Props extends Auth0ClientOptions {
  children: React.ReactNode;
  onRedirectCallback?: (appState?: any) => void;
  logout_redirect_uri?: string;
}

interface Auth0Output {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: (params?: {}) => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  getIdTokenClaims: (
    ...p: GetIdTokenClaimsOptions[]
  ) => Promise<IdToken> | undefined;
  loginWithRedirect: (
    ...p: RedirectLoginOptions[]
  ) => Promise<void> | undefined;
  loginWithMagicToken: (token: string) => Promise<void>;
  getTokenSilently: () => Promise<string | undefined>;
  getTokenWithPopup: (
    ...p: GetTokenWithPopupOptions[]
  ) => Promise<string> | undefined;
  logout: (...p: LogoutOptions[]) => void | undefined;
  logoutRaw: (...p: LogoutOptions[]) => void | undefined;
  logoutMagic: () => void;
}

const MAGIC_TOKEN_STORAGE_KEY = 'assured$$magicToken';
const getMagicToken = () => {
  return getUrlToken(MAGIC_TOKEN_STORAGE_KEY, 'magic');
};

const ADMIN_TOKEN_STORAGE_KEY = 'assured$$adminToken';
const getAdminToken = () => {
  return getUrlToken(ADMIN_TOKEN_STORAGE_KEY, 'admin');
};

//force the Auth0Output typing since we're going to set it
export const Auth0Context = React.createContext<Auth0Output>({} as Auth0Output);
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: React.FC<Auth0Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  logout_redirect_uri,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initTokenMode = async () => {
      setIsAuthenticated(true);
      setUser({});
      // const client = { getTokenSilently };
      // setAuth0(client);
      onRedirectCallback({});
      setLoading(false);
    };

    const initAuth0 = async () => {
      if (navigator.userAgent.includes('jsdom')) {
        // test mode, fall through
        return;
      }

      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      _auth0Client = auth0FromHook;

      setLoading(false);
    };

    const initNotLoggedIn = async () => {
      onRedirectCallback({});
      setLoading(false);
    };

    const postHook = async () => {
      // Respond to token requests
      pendingRequests.forEach(({ resolve, reject }) =>
        isAuthenticated
          ? getTokenSilently().then(resolve).catch(reject)
          : resolve(),
      );
      pendingRequests = [];
    };

    const magicToken = getMagicToken();
    const adminToken = getAdminToken();
    if (magicToken) {
      initTokenMode()
        .then(() => (_magicModeToken = magicToken))
        .then(postHook);
    } else if (adminToken) {
      initTokenMode()
        .then(() => (_adminModeToken = adminToken))
        .then(postHook);
    } else if (config.publicAccessMode) {
      initNotLoggedIn().then(postHook);
    } else {
      initAuth0().then(postHook);
    }
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const loginWithMagicToken = async (token: string) => {
    _magicModeToken = token;
    setLocalStorage(MAGIC_TOKEN_STORAGE_KEY, token);
    setIsAuthenticated(true);
    setUser({});
    onRedirectCallback({});
    setLoading(false);
  };

  const logoutMagic = () => {
    setLocalStorage(MAGIC_TOKEN_STORAGE_KEY, undefined);
    setIsAuthenticated(false);
    setUser(null);
    setLoading(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        loginWithMagicToken: token => loginWithMagicToken(token),
        getTokenSilently: () => getTokenSilently(),
        getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p) =>
          auth0Client?.logout({ returnTo: logout_redirect_uri, ...p }),
        logoutRaw: (...p) => auth0Client?.logout(...p),
        logoutMagic,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
