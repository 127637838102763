import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { DocumentStepComponent } from '@common/types/ClaimWorkflow';

import { useIntermediateValues } from '../../hooks';
import ProgressBar from '../ProgressBar';
import Generic from './Generic';
import Label from './Label';
import Title from './Title';
import {
  StepComponentControlsBackHookProps, StepComponentControlsTitleProps, StepComponentFC,
  StepComponentSharedProps, StepComponentShowsPrefillProps
} from './types/stepComponentTypes';
import Upload from './Upload';

const MODE_UPLOAD = null;
const MODE_PROCESSING = 'PROCESSING';
const MODE_MANUAL = 'MANUAL';
const MODE_CONFIRM = 'CONFIRM';

const EXTRACTION_CONFIDENCE_THRESHOLD = 50;

const formatDocumentType = (type: string) => {
  if (type === 'INSURANCE_CARD') {
    return 'card';
  } else {
    return 'document';
  }
};

const generateDemoDocument = (type: string) => {
  if (type === 'INSURANCE_CARD') {
    return {
      insuredName: 'Amy Smith',
      agency: 'Test Insurance Company',
      agencyPolicyNumber: 'T3STABC123',
    };
  } else if (type === 'DRIVER_LICENSE') {
    return {
      driverLicenseName: 'Amy Smith',
      driverLicenseState: 'California',
      driverLicenseNumber: '123456789',
    };
  }
};

interface UploadDocumentData {
  uploadDocument: {
    document: {
      id: string;
      parsedContent: Record<string, any>;
    };
    extractionConfidence: number;
  };
}

interface UploadDocumentVars {
  source: string;
  feature: string;
}

const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($source: String!, $feature: DocumentFeature!) {
    uploadDocument(source: $source, feature: $feature) {
      document {
        id
        parsedContent
      }
      extractionConfidence
    }
  }
`;

type DocumentProps = StepComponentSharedProps<
  DocumentStepComponent,
  object | null
> &
  StepComponentShowsPrefillProps &
  StepComponentControlsTitleProps &
  StepComponentControlsBackHookProps &
  Record<string, any>;

const Document: StepComponentFC<DocumentProps> = ({
  step_component,
  showsPrefill,
  primaryValue,
  updateValue,
  className,
  titleClassName,
  registerBackHook,
  removeBackHook,
  workflowChannel,
  ...rest
}) => {
  const isSidekick = workflowChannel === 'sidekick';

  const [mode, setMode] = useState<string | null>(
    isSidekick ? MODE_MANUAL : MODE_UPLOAD,
  );

  const { obj, setObj, submit } = useIntermediateValues({
    initialObj: {
      [step_component.content_field || 'userContent']:
        isSidekick && step_component.default_manual_document
          ? step_component.default_manual_document
          : {},
    },
    step_component,
    updateValue,
    postProcess: obj => {
      let processed = { ...obj };
      processed[step_component.content_field || 'userContent'] = JSON.stringify(
        obj[step_component.content_field || 'userContent'],
      );
      return processed;
    },
  });

  const [uploadDocument, { loading: uploadLoading, data: uploadedData }] =
    useMutation<UploadDocumentData, UploadDocumentVars>(UPLOAD_DOCUMENT);

  const conf_ = uploadedData?.uploadDocument.extractionConfidence || 0;
  const failedExtraction =
    conf_ > -1 && // -1 means no extraction was attempted
    conf_ < EXTRACTION_CONFIDENCE_THRESHOLD;

  useEffect(() => {
    if (uploadLoading) {
      // Currently don't switch until processed
      setMode(MODE_PROCESSING);
    } else if (uploadedData) {
      const newObj = {
        [step_component.document_field]: {
          connect: { id: uploadedData.uploadDocument.document.id },
        },
        [step_component.content_field || 'userContent']: {
          ...uploadedData.uploadDocument.document.parsedContent,
        },
      };
      if (step_component.skip_ocr_confirm && !failedExtraction) {
        setMode(MODE_PROCESSING);
      } else {
        setMode(MODE_CONFIRM);
      }
      setObj({
        ...obj,
        ...newObj,
      });
    }
  }, [uploadLoading, uploadedData, failedExtraction]);

  useEffect(() => {
    if (
      obj &&
      obj[step_component.document_field] &&
      step_component.skip_ocr_confirm &&
      !failedExtraction
    ) {
      submit();
    }
  }, [obj, failedExtraction]);

  useEffect(() => {
    const backHook = () => {
      if (mode === MODE_CONFIRM || mode === MODE_MANUAL) {
        setMode(MODE_UPLOAD);
        return true;
      }
      return false;
    };
    registerBackHook(backHook);
    return () => removeBackHook(backHook);
  }, [mode]);

  if (mode === MODE_UPLOAD) {
    return (
      <div className={className}>
        <Title title={step_component.title} titleClassName={titleClassName} />
        {showsPrefill ? (
          <div
            className="ClaimWorkflowInner btn btn-magic mx-0 py-2"
            onClick={() => {
              const doc =
                generateDemoDocument(step_component.document_type) || {};
              setObj({ [step_component.content_field || 'userContent']: doc });
              setMode(MODE_MANUAL);
            }}
          >
            ✨ Use demo {formatDocumentType(step_component.document_type)} ✨
          </div>
        ) : null}
        <Upload
          className="ClaimWorkflowInner"
          step_component={{
            // FIXME(06-19-2020) unsure if I should set this, since previously it was undefined
            field: step_component.document_field,
            mode: 'document',
            type: 'upload',
            document_type: step_component.document_type,
            example_illustration: step_component.example_illustration,
            skip_label:
              step_component.manual_label ||
              'Enter information manually instead',
          }}
          forceSubmit={() => {
            if (step_component.default_manual_document) {
              setObj({
                [step_component.content_field || 'userContent']:
                  step_component.default_manual_document,
              });
            }
            setMode(MODE_MANUAL);
          }}
          primaryValue={null}
          updateValue={(_, value) => {
            value &&
              !Array.isArray(value) &&
              value.source &&
              uploadDocument({
                variables: {
                  feature: step_component.document_type,
                  source: value.source,
                },
              });
          }}
          additionalButtons={
            step_component.skip_label ? (
              <div
                className="ClaimWorkflowInner mt-4 btn btn-subtle mx-0 py-2"
                onClick={submit}
              >
                {step_component.skip_label}
              </div>
            ) : null
          }
        />
      </div>
    );
  }

  if (mode === MODE_PROCESSING) {
    return (
      <div className={classNames(className, 'ClaimWorkflowInner')}>
        <div className="mt-4 text-cool-gray-600">
          Reading document contents...
        </div>
        <ProgressBar countdownMs={15000} className="mt-2 mx-8" />
      </div>
    );
  }

  if (mode === MODE_MANUAL || mode === MODE_CONFIRM) {
    let documentComponents = step_component.document_components;
    let parsed = uploadedData?.uploadDocument?.document?.parsedContent;
    let failedToParse =
      mode === MODE_CONFIRM &&
      (!parsed || !Object.keys(parsed).length || failedExtraction);

    // Disabled: Would show only successful fields, for demos
    // if (
    //   !failedToParse &&
    //   parsed &&
    //   step_component.document_type === 'INSURANCE_CARD'
    // ) {
    //   documentComponents = Object.keys(parsed).map(k => ({
    //     field: k,
    //     title: k,
    //     type: 'string',
    //   }));
    // }

    return (
      <div className={className}>
        <Title
          title={
            (mode === MODE_MANUAL
              ? step_component.manual_title
              : failedToParse
              ? step_component.extraction_failed_title
              : step_component.confirm_title) || step_component.title
          }
          titleClassName={titleClassName}
        />
        {documentComponents.map(currentComponentDef => {
          if (
            (mode === MODE_MANUAL ||
              failedToParse ||
              (currentComponentDef.field &&
                !obj[step_component.content_field || 'userContent'][
                  currentComponentDef.field
                ])) &&
            currentComponentDef.autofill_only
          ) {
            return null;
          }
          return (
            <div key={currentComponentDef.field} className="ClaimWorkflowInner">
              <Label
                step_component={{ label: currentComponentDef.title }}
                className="mt-6 -mb-2"
              />
              <Generic
                step_component={
                  currentComponentDef?.manual_component || currentComponentDef
                }
                primaryValue={
                  obj[step_component.content_field || 'userContent'][
                    currentComponentDef.field
                  ]
                }
                updateValue={(k, v) => {
                  k &&
                    setObj({
                      ...obj,
                      [step_component.content_field || 'userContent']: {
                        ...obj[step_component.content_field || 'userContent'],
                        [k]: v,
                      },
                    });
                }}
                forceSubmit={() => {}}
                {...rest}
              />
            </div>
          );
        })}
        <div className="mt-6 ClaimWorkflowInner flex flex-wrap justify-center">
          <button className="btn btn-blue sm:order-last" onClick={submit}>
            {step_component.submit_label || `Submit document`}
          </button>
          {step_component.secondary_skip_label || isSidekick ? (
            <button className="btn btn-subtle sm:order-first" onClick={submit}>
              {step_component.secondary_skip_label || "I don't know"}
            </button>
          ) : (
            <button
              className="btn btn-subtle sm:order-first"
              onClick={() => setMode(MODE_UPLOAD)}
            >
              Back
            </button>
          )}
        </div>
      </div>
    );
  }

  return null;
};

Document.stepConfig = {
  controlsTitle: true,
};

export default Document;
