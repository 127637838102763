import React from 'react';
import MaskedInput from 'react-text-mask';

import IncrementButton from './IncrementButton';

interface IncrementableSmallNumberProps {
  value: number;
  minimum: number;
  onRawChange: (v: number) => void;
  [k: string]: any;
}

const IncrementableSmallNumber: React.FC<IncrementableSmallNumberProps> = ({
  value,
  minimum,
  onRawChange,
  ...rest
}) => {
  return (
    <div className="flex items-center justify-center">
      <IncrementButton
        direction="negative"
        value={value}
        updateValue={v => onRawChange(v)}
        disabled={value === minimum}
      />
      <MaskedInput value={value} {...rest} />
      <IncrementButton
        direction="positive"
        value={value}
        updateValue={v => onRawChange(v)}
      />
    </div>
  );
};

export default IncrementableSmallNumber;
