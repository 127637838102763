import React from 'react';
import { Link } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import Base from '../layouts/Base';
import { useAuth0 } from '../services/auth';

interface GenericErrorPageProps {
  error?: ApolloError;
  isExpiredToken?: boolean;

  title?: string;
  errorMessage?: string;
  showRestartButton?: boolean;
}

const ExpiredErrorContent: React.FC<{}> = () => {
  const { logoutMagic } = useAuth0();
  return (
    <Base className="Home">
      <h2 className="text-lg text-cool-gray-600">Your session has expired.</h2>
      <h4 className="mt-2 text-sm text-cool-gray-600">
        Please use the original link texted to you to resume your session where
        you left off, or{' '}
        <Link
          className="text-blue-600 hover:underline"
          to="/"
          onClick={() => {
            logoutMagic();
          }}
        >
          start over
        </Link>
        .
      </h4>
    </Base>
  );
};

const GenericErrorPage: React.FC<GenericErrorPageProps> = ({
  error,
  isExpiredToken,
  title,
  errorMessage,
  showRestartButton,
}) => {
  const { logoutMagic } = useAuth0();

  let messages: string[];

  if (errorMessage) {
    messages = [errorMessage];
  } else if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors.every(e => e && e.message)
  ) {
    messages = error.graphQLErrors.map(({ message }) =>
      message === 'jwt expired'
        ? 'Your access to the claims portal has expired.'
        : message,
    );
  } else if (error && error.networkError) {
    //https://github.com/apollographql/apollo-link/issues/300

    const result = (error.networkError as any).result;
    messages = result
      ? result.message
        ? [result.message]
        : result.errors
        ? result.errors.map((e: any) => e.message)
        : JSON.stringify(result)
      : ['An unknown error occured. Please check your network connection.'];
  } else if (isExpiredToken) {
    return <ExpiredErrorContent />;
  } else {
    messages = [`Your access may have expired.`];
  }

  if (error?.message && error?.message?.indexOf('401') !== -1) {
    return <ExpiredErrorContent />;
  }

  return (
    <Base className="Home">
      <h2 className="text-lg text-cool-gray-600">
        {title || 'Unable to connect to Assured.'}
      </h2>
      <h2 className="mt-2 text-sm text-cool-gray-600">
        {messages.map((message, i) => (
          <span key={i}>{message}</span>
        ))}
      </h2>
      {showRestartButton ? (
        <div className="mt-2 text-center">
          <Link
            className="inline-block btn btn-blue"
            to="/"
            onClick={() => {
              logoutMagic();
            }}
          >
            Start over
          </Link>
        </div>
      ) : null}
    </Base>
  );
};
export default GenericErrorPage;
