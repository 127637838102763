import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useDebounce from 'react-use/lib/useDebounce';

import Datepicker from '@common/components/Datepicker';
import { MedicalAppointmentsStepComponent } from '@common/types/ClaimWorkflow';
import { MedicalAppointmentEntry } from '@common/types/ClaimWorkflow/MedicalAppointments';
import { CalendarIcon, XIcon } from '@heroicons/react/solid';

import Dropdown from '../../Dropdown';
import Modal from '../../Modal';
import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';

interface MedicalAppointmentsProps
  extends StepComponentSharedProps<
    MedicalAppointmentsStepComponent,
    any[] | any
  > {
  forceSubmit: () => void;
}

const BASE_APPOINTMENT = {
  type: '',
  date: null,
  ongoing: false,
  description: '',
  medicalProviderId: undefined,
} as const;

export const formatMedicalAppointmentType = (type: string) => {
  if (type === 'DOCTOR_VISIT') {
    return 'Doctor visit';
  } else if (type === 'PHYSICAL_THERAPY') {
    return 'Physical therapy';
  } else if (type === 'TEST') {
    return 'Test / procedure';
  } else if (type === 'OTHER') {
    return 'Other';
  } else {
    return type;
  }
};

const MedicalAppointments: StepComponentFC<MedicalAppointmentsProps> = ({
  step_component,
  updateValue,
  primaryValue,
  className,
  forceSubmit,
}) => {
  const [appointments, setAppointments] = useState<MedicalAppointmentEntry[]>(
    step_component.existing_appointments || [],
  );
  const [showModal, setShowModal] = useState(false);

  const [newAppointment, setNewAppointment] = useState<MedicalAppointmentEntry>(
    {
      ...BASE_APPOINTMENT,
    },
  );

  const removeAppointment = (item: MedicalAppointmentEntry) =>
    setAppointments(a => a.filter(x => x !== item));

  const newAppointmentIsComplete = !!(
    newAppointment.date &&
    newAppointment.description &&
    newAppointment.type &&
    typeof newAppointment.medicalProviderId !== 'undefined'
  );

  return (
    <div className={classNames('mt-4 relative', className)}>
      <Modal
        open={showModal}
        title="Appointment details"
        body={
          <div className="mt-6 mb-8">
            <div className="text-cool-gray-900">
              <Datepicker
                value={newAppointment.date}
                wide={true}
                placeholderText="Appointment date"
                onChange={date => setNewAppointment(a => ({ ...a, date }))}
                showTimeSelect={false}
                minDate={new Date()}
              />
            </div>

            <div className="mt-4">
              <Dropdown
                placeholder="Appointment type"
                value={newAppointment.type}
                options={[
                  'DOCTOR_VISIT',
                  'PHYSICAL_THERAPY',
                  'TEST',
                  'OTHER',
                ].map(value => ({
                  value,
                  label: formatMedicalAppointmentType(value),
                }))}
                onChange={e => {
                  const type = e.target.value;
                  setNewAppointment(a => ({ ...a, type }));
                }}
              />
            </div>

            <div className="mt-4">
              <Dropdown
                placeholder="Medical provider (e.g. doctor)"
                value={
                  newAppointment.medicalProviderId === null
                    ? '__other__'
                    : newAppointment.medicalProviderId
                }
                options={(step_component.existing_medical_providers || [])
                  .map(p => ({
                    value: p.id || '',
                    label: p.name || 'Unnamed Provider',
                  }))
                  .concat([
                    { value: '__other__', label: 'Other (specify below)' },
                  ])}
                onChange={e => {
                  const medicalProviderId = e.target.value;
                  setNewAppointment(a => ({
                    ...a,
                    medicalProviderId:
                      medicalProviderId === '__other__'
                        ? null
                        : medicalProviderId,
                  }));
                }}
              />
            </div>

            <textarea
              className="mt-4 textbox text-base text-cool-gray-900"
              placeholder={
                'In a few words, describe the planned appointment. For example, "X-ray session" or "Consultation".'
              }
              rows={4}
              value={newAppointment.description || ''}
              onChange={e => {
                const description = e.target.value;
                setNewAppointment(a => ({ ...a, description }));
              }}
            />

            <div className="mt-4 relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="ongoing"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                  checked={!!newAppointment.ongoing}
                  onChange={e => {
                    const ongoing = e.target.checked;
                    setNewAppointment(a => ({ ...a, ongoing }));
                  }}
                />
              </div>
              <label htmlFor="ongoing" className="ml-3 text-sm text-left">
                <span className="font-medium text-gray-700">
                  This appointment is ongoing
                </span>{' '}
                <span className="text-gray-500">
                  (for example, a weekly physical therary session)
                </span>
              </label>
            </div>
          </div>
        }
        actions={[
          {
            title: 'Add appointment',
            primary: true,
            onClick: () => {
              if (!newAppointmentIsComplete) {
                return;
              }

              setAppointments(a => [...a, newAppointment]);
              setShowModal(false);
              setTimeout(() => setNewAppointment({ ...BASE_APPOINTMENT }), 250);
            },
            className: !newAppointmentIsComplete ? 'btn-disabled' : '',
          },
          {
            title: 'Never mind',
            className: 'sm:order-first',
            onClick: () => {
              setShowModal(false);
              setTimeout(() => setNewAppointment({ ...BASE_APPOINTMENT }), 250);
            },
          },
        ]}
      />

      {appointments.length ? (
        <div className="">
          {appointments.map(item => {
            return (
              <div
                key={item.id || `${item.type}${item.date}${item.description}`}
                className="bg-cool-gray-50 py-3 px-4 rounded-md border border-cool-gray-300 mb-4 text-left flex items-center"
              >
                <div className="flex-1">
                  <div className="text-cool-gray-800 font-medium">
                    {moment(item.date).format('LL')} &middot;{' '}
                    {formatMedicalAppointmentType(item.type)}
                  </div>
                  <div className="text-cool-gray-500 text-sm mt-1 leading-5">
                    {item.medicalProviderId ? (
                      <>
                        {step_component.existing_medical_providers?.find(
                          p => p.id === item.medicalProviderId,
                        )?.name || 'Unknown provider'}{' '}
                        &middot;{' '}
                      </>
                    ) : null}
                    {item.description}
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => removeAppointment(item)}
                >
                  <XIcon className="w-4 h-4 text-cool-gray-400 hover:text-red-500" />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="">
        <button
          className={classNames('btn mt-2 py-2 inline-flex items-center')}
          onClick={() => setShowModal(true)}
        >
          <CalendarIcon className="w-5 h-5 -mt-0.5 mr-2" />
          <span>
            {appointments.length
              ? 'Add another appointment'
              : 'Add an appointment'}
          </span>
        </button>
      </div>
      <div className="mt-4 flex justify-center flex-wrap sm:flex-no-wrap">
        <button
          className={classNames(
            'btn btn-blue sm:order-last',
            !appointments.length && 'btn-disabled',
          )}
          onClick={() => {
            if (appointments.length) {
              const value = appointments.map(r => ({
                id: (r as any).id,
                ...r,
              }));
              updateValue(step_component.field, value);
            }
          }}
        >
          Continue
        </button>
        <button
          className={classNames(
            'btn btn-subtle sm:order-first',
            !!appointments.length && 'btn-disabled',
          )}
          onClick={() => {
            if (!appointments.length) {
              updateValue(step_component.field, []);
            }
          }}
        >
          I don't have any
        </button>
      </div>
    </div>
  );
};

MedicalAppointments.stepConfig = {};

export default MedicalAppointments;
