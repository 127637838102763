import React from 'react';
import classNames from 'classnames';
import { isPlainObject, isEmpty } from 'lodash';

const ReactAnimatedWeather =
  typeof window !== 'undefined'
    ? require('react-animated-weather').default
    : () => null;

const WeatherForecast: React.FC<{
  className?: string;
  small?: true;
  forecast: any;
}> = ({ forecast, small, className }) => {
  if (!isPlainObject(forecast) || isEmpty(forecast) || !forecast?.icon) {
    return null;
  }
  const iconKey = forecast.icon.toUpperCase().replace(/-/g, '_');
  const useWhiteColor = document.body.classList.contains('tenant-tesla');

  return (
    <>
      <div
        data-not-copyable
        className={classNames('mt-4 mb-4 text-center w-full', className)}
      >
        <div className="mx-auto" style={{ maxWidth: 120 }}>
          <ReactAnimatedWeather
            color={useWhiteColor ? '#fff' : '#718096'}
            icon={iconKey}
            size={120}
            animate={iconKey.indexOf('NIGHT') === -1} // Moon animation issues on mobile.
          />
        </div>
        <div className={`${small ? 'text-l' : 'text-2xl'}`}>
          {forecast.summary}
        </div>
        <div className={`${small ? 'text-m' : 'text-xl'}`}>
          {forecast.temperature.toFixed(0)}°F
        </div>
      </div>
    </>
  );
};
export default WeatherForecast;
