export const setSessionStorage = (key: string, value: any) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
};

export const getSessionStorage = (key: string) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
};

export const setLocalStorage = (key: string, value: any) => {
  try {
    if (value) {
      window.localStorage.setItem(key, value);
    } else {
      window.localStorage.removeItem(key);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getLocalStorage = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
};
export const isWindows = () => {
  return window.navigator?.platform?.toLowerCase().indexOf('win') !== -1;
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  if (!phoneNumberString) {
    return '';
  }
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumberString;
};
