import React from 'react';

import { VehicleSeatmapStepComponent } from '@common/types/ClaimWorkflow';

import Seatmap from '../Seatmap';
import { StepComponentFC, StepComponentSharedProps } from './types/stepComponentTypes';

type VehicleSeatmapProps = StepComponentSharedProps<
  VehicleSeatmapStepComponent,
  string[] | string
>;

const VehicleSeatmap: StepComponentFC<VehicleSeatmapProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
}) => {
  return (
    <div className={className}>
      <Seatmap
        numSeats={step_component.num_seats}
        allowAnySeat={step_component.allow_any_seat}
        disabledSeats={step_component.disabled_seats}
        selected={
          step_component.single
            ? [primaryValue as string].filter(x => !!x)
            : (primaryValue as string[])
        }
        onClick={seat => {
          if (step_component.single) {
            updateValue(step_component.field, seat);
          } else {
            if (primaryValue.includes(seat)) {
              updateValue(
                step_component.field,
                (primaryValue as string[]).filter(s => s !== seat),
              );
            } else {
              updateValue(
                step_component.field,
                (primaryValue as string[]).concat([seat]),
              );
            }
          }
        }}
      />
    </div>
  );
};

VehicleSeatmap.stepConfig = {
  manualSubmit: true,
};

export default VehicleSeatmap;
