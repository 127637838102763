import React from 'react';
import { GraphicInlineComponent } from '@common/types/ClaimWorkflow';

import {
  InlineComponentSharedProps,
  InlineFC,
} from '../types/inlineComponentTypes';
import TenantLogo from '../../TenantLogo';

const Graphic: InlineFC<InlineComponentSharedProps<GraphicInlineComponent>> = ({
  value: graphicData,
}) => {
  return (
    <div>
      {graphicData.value.graphic.type === 'tenantLogo' ? (
        <TenantLogo
          tenant={graphicData.value.graphic.tenant}
          size="large"
          className={'block mx-auto mb-8'}
        />
      ) : null}
    </div>
  );
};

export default Graphic;
