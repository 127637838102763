/**
 * FIXME(04-15-2021, theo): This file is very large and rather atrocious. It needs to be
 * majorly refactored into smaller helpers, a better state-based data model, etc. All in
 * good time, hopefully.
 */

import 'mapbox-gl/dist/mapbox-gl.css';

import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';
//@ts-ignore
import { THREE, Threebox } from 'threebox-plugin';

import * as turf from '@turf/turf';

import config from '../../config';
import LocationDirection from '../ClaimWorkflow/LocationDirection';
import Select from '../ClaimWorkflow/Select';
import SelectMulti from '../ClaimWorkflow/SelectMulti';
import Title from '../ClaimWorkflow/Title';
import ExampleIllustration from '../elements/ExampleIllustration';
//@ts-ignore
import carModel from '../VehicleDamagePicker/new_car.obj';
import {
  findNearestPoints, generateTrajectories, MINIMUM_PATH_LENGTH_METERS, NEEDS_LEADING_VEHICLE,
  Position
} from './helpers';
import { BUILDING_LAYER } from './layers';

// Bugfix for Webpack not properly building mapbox-gl
// https://stackoverflow.com/a/66140436/1483482
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line import/no-webpack-loader-syntax
  (mapboxgl as any).workerClass =
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
}

enum ReconstructionState {
  UNSUPPORTED_DEVICE,
  MOVEMENT_STATE_ENTRY,
  STATIONARY_ANGLE_ENTRY,
  STATIONARY_FACTORS_ENTRY,
  PATH_ENTRY,
  SPEED_ENTRY,
  LEADING_VEHICLE_ENTRY,
  ANALYSIS,
}

enum MovementState {
  STATIONARY = 'STATIONARY',
  MOVING = 'MOVING',
}

const STATIONARY_COLLISION_TYPES = {
  hit_by_third_party: 'Hit by another car',
  opened_door: 'Opened car door',
  parking_brake: 'Parking brake failed',
  other: 'Other',
};

(window as any).THREE = THREE;

let tb: any = null;
let _pendingRotationAnimationFrame: number | undefined;

const YOU_COLOR = '#ff0000';
const OTHER_COLOR = '#0000ff';
const YOU_ALTERNATE_COLOR = '#EF4444';
const OTHER_ALTERNATE_COLOR = '#2563EB';

const CURVE_TOLERANCE = 0.000005;

mapboxgl.accessToken = config.mapboxAccessToken;

interface CollisionReconstructionProps {
  responsive?: boolean;
  recordingMode?: boolean;
  showStats?: boolean;
  hideCharts?: boolean;
  collisionLocation: { lat: number; lng: number };
  speedOptions?: { value: number }[];
  selfWasStationary?: boolean;
  otherWasStationary?: boolean;
  selfLabel?: string;
  otherPartyLabel?: string;
  reset?: () => void;
  submit?: (data: any) => void;
  initialData?: any;
  playbackOnly?: boolean;
  demoMode?: boolean;
  registerBackHook?: (f: () => boolean) => void;
}

interface Party {
  id: string;
  name: string;
  type: 'car';
  color: string;
  alternate_color: string;
  model: any | null;
  movement_state: MovementState | null;
  is_leading?: boolean;
  stationary_position?: Position;
  stationary_angle?: number;
  path: Position[];
  keyframes: {
    percent: number;
    position: Position;
    path: Position[];
    reverse: boolean;
    speed: number;
  }[];
  _previous_path_for_undo_restore: Position[] | null;
}

const CollisionReconstruction: React.FC<CollisionReconstructionProps> = ({
  responsive,
  recordingMode,
  showStats,
  hideCharts,
  collisionLocation: initialCollisionLocation,
  speedOptions,
  selfWasStationary,
  otherWasStationary,
  selfLabel,
  otherPartyLabel,
  reset,
  submit,
  initialData,
  playbackOnly,
  demoMode,
  registerBackHook,
}) => {
  const [parties, setParties] = useState<Party[]>([
    {
      id: 'you',
      name: selfLabel || `[[your vehicle]]`,
      type: 'car',
      color: YOU_COLOR,
      alternate_color: YOU_ALTERNATE_COLOR,
      model: null as any,
      ...(initialData?.parties
        ? {
            path: initialData.parties[0].path,
            keyframes: initialData.parties[0].keyframes,
            movement_state:
              initialData.parties[0].movement_state ||
              (selfWasStationary ? MovementState.STATIONARY : null),
            stationary_position: initialData.parties[0].stationary_position,
            stationary_angle: initialData.parties[0].stationary_angle,
            is_leading: initialData.parties[0].is_leading,
          }
        : {
            path: [],
            keyframes: [],
            movement_state: selfWasStationary ? MovementState.STATIONARY : null,
          }),
      _previous_path_for_undo_restore: null,
    },
    {
      id: 'other',
      name: otherPartyLabel || 'the [[other vehicle]]',
      type: 'car',
      color: OTHER_COLOR,
      alternate_color: OTHER_ALTERNATE_COLOR,
      model: null as any,
      ...(initialData?.parties
        ? {
            path: initialData.parties[1].path,
            keyframes: initialData.parties[1].keyframes,
            movement_state:
              initialData.parties[1].movement_state ||
              (otherWasStationary ? MovementState.STATIONARY : null),
            stationary_position: initialData.parties[1].stationary_position,
            stationary_angle: initialData.parties[1].stationary_angle,
            is_leading: initialData.parties[1].is_leading,
          }
        : {
            path: [],
            keyframes: [],
            movement_state: otherWasStationary
              ? MovementState.STATIONARY
              : null,
          }),
      _previous_path_for_undo_restore: null,
    },
  ]);
  const [currentPartyIndex, setCurrentPartyIndex] = useState(0);
  const currentParty = parties[currentPartyIndex];

  const [reconstructionState, setReconstructionState] = useState(
    ReconstructionState.MOVEMENT_STATE_ENTRY,
  );

  const [hasSeenInstructions, setHasSeenInstructions] = useState(false);
  const [speedEntryAvailable, setSpeedEntryAvailable] = useState(true);
  const [analysisData, setAnalysisData] = useState<ReturnType<
    typeof generateTrajectories
  > | null>(null);

  const [adjustedCollisionLocation, setAdjustedCollisionLocation] = useState<
    typeof initialCollisionLocation | null
  >(null);
  const collisionLocation =
    adjustedCollisionLocation || initialCollisionLocation;

  const drawState = useRef<any>({ partyId: null, drawing: false });
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const offerUndo =
    !(
      reconstructionState === ReconstructionState.MOVEMENT_STATE_ENTRY &&
      (currentPartyIndex === 0 ||
        (selfWasStationary && currentPartyIndex === 1))
    ) &&
    reconstructionState !== ReconstructionState.ANALYSIS &&
    reconstructionState !== ReconstructionState.UNSUPPORTED_DEVICE &&
    !(
      reconstructionState === ReconstructionState.PATH_ENTRY &&
      !hasSeenInstructions
    );

  /**
   * Dispose of Threebox when component unmounts, to avoid memory leak.
   */
  const disposeThreebox = () => {
    try {
      tb?.dispose();
    } catch (e) {
      // fall through e.g. if already destroyed
    }
    tb = null;
    (window as any).tb = null;
  };
  useEffect(() => {
    return () => disposeThreebox();
  }, []);

  /**
   * Generate the Mapbox map on initial load, and attach touch listeners to handle
   * drawing-based input.
   */
  useEffect(() => {
    if (
      ![
        ReconstructionState.PATH_ENTRY,
        ReconstructionState.SPEED_ENTRY,
        ReconstructionState.ANALYSIS,
      ].includes(reconstructionState) ||
      mapRef.current
    ) {
      return;
    }

    let map: mapboxgl.Map;
    try {
      map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: collisionLocation,
        zoom: 18,
      });
      mapRef.current = map;
    } catch (e) {
      setReconstructionState(ReconstructionState.UNSUPPORTED_DEVICE);
      window.analytics.track(
        'CollisionReconstruction failed due to unsupported device',
        {
          error: e.message,
        },
      );
      return;
    }

    map.on('load', () => {
      drawInitial3DCars();

      // Disable interaction while the map is zoom (e.g. flyTo in progress)
      map.on('zoomstart', e => {
        map.getContainer().style.pointerEvents = 'none';
      });
      map.on('zoomend', e => {
        map.getContainer().style.pointerEvents = '';
      });

      // Start drawing event
      map.on('mousedown', e => {
        startDraw(e);
      });
      map.on('touchstart', e => {
        startDraw(e);
      });

      // Finish drawing event
      map.on('mouseup', e => {
        stopDraw();
      });
      map.on('touchend', e => {
        stopDraw();
      });

      // During drawing event
      map.on('mousemove', e => {
        continueDrawWithPoint([e.lngLat.lng, e.lngLat.lat]);
      });
      map.on('touchmove', e => {
        e.preventDefault();
        continueDrawWithPoint([e.lngLat.lng, e.lngLat.lat]);
      });

      // When arrows are used to move map
      map.on('moveend', e => {
        if ((e as any)?.updateCollisionLocation) {
          const center = e.target.getCenter();
          setAdjustedCollisionLocation({ lat: center.lat, lng: center.lng });
        }
      });
    });
  }, [reconstructionState]);

  /**
   * State machine to listen to changes to ReconstructionState and properly
   * dispatch map updates.
   */
  useEffect(() => {
    if (initialData) {
      setReconstructionState(ReconstructionState.ANALYSIS);
      mapRef.current?.on('load', () => {
        // FIXME(04-15-2021, theo): We need to just wait for threejs models to load fully
        // first in playback mode, rather than this time-based delay.
        setTimeout(() => enterAnalysisMode(), playbackOnly ? 1250 : 250);
      });
      return;
    } else if (
      reconstructionState == ReconstructionState.MOVEMENT_STATE_ENTRY ||
      reconstructionState === ReconstructionState.STATIONARY_ANGLE_ENTRY
    ) {
      // Destroy map if it exists. This occurs when navigating back/forth in the stack.
      disposeThreebox();
      try {
        mapRef.current?.remove();
      } catch (e) {
        // fall through, e.g. if map can't be removed since Threebox already removed it
      }
      mapRef.current = null;

      // Skip first party if they were set to stationary previously
      if (
        reconstructionState == ReconstructionState.MOVEMENT_STATE_ENTRY &&
        selfWasStationary &&
        currentPartyIndex === 0
      ) {
        setCurrentPartyIndex(1);
        return;
      }
    } else if (reconstructionState === ReconstructionState.PATH_ENTRY) {
      if (parties.some(p => p.movement_state === MovementState.STATIONARY)) {
        setHasSeenInstructions(true);
      }
      if (currentParty) {
        if (currentParty.movement_state === MovementState.STATIONARY) {
          setCurrentPartyIndex(i => i + 1);
          return;
        }

        drawState.current = {
          partyId: currentParty.id,
          drawing: false,
          path: [],
        };
      } else {
        setCurrentPartyIndex(0);
        setReconstructionState(ReconstructionState.SPEED_ENTRY);
        drawState.current = {
          partyId: null,
          drawing: false,
          keyframe_index: 0,
        };
      }
    } else if (reconstructionState === ReconstructionState.SPEED_ENTRY) {
      if (!currentParty) {
        enterAnalysisMode();
      } else if (currentParty.movement_state === MovementState.STATIONARY) {
        setCurrentPartyIndex(i => i + 1);
      } else {
        animateForSpeedEntry();
      }
    }
  }, [reconstructionState, currentParty]);

  /**
   * Undo the most recent action on the history stack.
   */
  const undo = () => {
    /**
     * Generate a linear sequence of all the steps involved in the collision process.
     * This entire component should really be refactored with this order as a top-level
     * state machine, then used to deterministically render everything else, but ah well,
     * maybe one day...
     */
    const buildSteps = () => {
      type Step = {
        state: ReconstructionState;
        index?: number;
        keyframe_index?: number;
      };

      let steps = parties.map((_, index) =>
        (index === 0 && selfWasStationary) ||
        (index === 1 && otherWasStationary)
          ? null
          : {
              state: ReconstructionState.MOVEMENT_STATE_ENTRY,
              index,
            },
      ) as (Step | null)[];

      if (parties.every(p => p.movement_state === MovementState.STATIONARY)) {
        steps.push({ state: ReconstructionState.STATIONARY_FACTORS_ENTRY });
      } else {
        steps = steps
          .concat(
            parties.map((party, index) =>
              party.movement_state === MovementState.STATIONARY
                ? {
                    state: ReconstructionState.STATIONARY_ANGLE_ENTRY,
                    index,
                  }
                : null,
            ),
          )
          .concat(
            parties.map((party, index) =>
              party.movement_state === MovementState.MOVING
                ? {
                    state: ReconstructionState.PATH_ENTRY,
                    index,
                  }
                : null,
            ),
          )
          .concat(
            ([] as Step[]).concat.apply(
              [],
              parties.map((party, index) =>
                party.movement_state === MovementState.MOVING
                  ? [0, 1].map(i => ({
                      state: ReconstructionState.SPEED_ENTRY,
                      index,
                      keyframe_index: i,
                    }))
                  : [],
              ),
            ),
          )
          .concat(
            parties.some(p => p.is_leading) ||
              reconstructionState === ReconstructionState.LEADING_VEHICLE_ENTRY
              ? [
                  {
                    state: ReconstructionState.LEADING_VEHICLE_ENTRY,
                  },
                ]
              : null,
          )
          .concat([
            {
              state: ReconstructionState.ANALYSIS,
            },
          ]);
      }
      return steps.filter(s => s !== null) as Step[];
    };

    const steps = buildSteps();
    const currentStepIndex = steps.findIndex(
      s =>
        s.state === reconstructionState &&
        (typeof s.index === 'undefined' || s.index === currentPartyIndex) &&
        (typeof s.keyframe_index === 'undefined' ||
          s.keyframe_index === drawState.current.keyframe_index),
    );

    const desiredIndex = currentStepIndex - 1;
    const desiredStep = steps[desiredIndex];

    if (!desiredStep) {
      // Panic, abort
      return false;
    }

    if (desiredStep.state === ReconstructionState.MOVEMENT_STATE_ENTRY) {
      setReconstructionState(ReconstructionState.MOVEMENT_STATE_ENTRY);
      setCurrentPartyIndex(desiredStep.index!);
    } else if (
      desiredStep.state === ReconstructionState.STATIONARY_ANGLE_ENTRY
    ) {
      setReconstructionState(ReconstructionState.STATIONARY_ANGLE_ENTRY);
      setCurrentPartyIndex(desiredStep.index!);
    } else if (desiredStep.state === ReconstructionState.PATH_ENTRY) {
      playCarLoops({
        parties,
        paths: parties.map((p, i) =>
          i >= (desiredStep.index || -1)
            ? []
            : p._previous_path_for_undo_restore || p.path,
        ),
      });
      setParties(parties =>
        parties.map((p, i) => {
          p.path =
            i >= (desiredStep.index || -1)
              ? []
              : p._previous_path_for_undo_restore || p.path;
          return p;
        }),
      );
      setCurrentPartyIndex(desiredStep.index!);
      setReconstructionState(ReconstructionState.PATH_ENTRY);
      animateForPathEntry();
    } else if (desiredStep.state === ReconstructionState.SPEED_ENTRY) {
      drawState.current.keyframe_index = desiredStep.keyframe_index;
      setParties(parties =>
        parties.map((p, i) => {
          p.is_leading = undefined;
          return p;
        }),
      );
      setReconstructionState(ReconstructionState.SPEED_ENTRY);
      setCurrentPartyIndex(desiredStep.index!);
      animateForSpeedEntry();
      setSpeedEntryAvailable(false);
      setTimeout(() => setSpeedEntryAvailable(true), 300);
    } else if (
      desiredStep.state === ReconstructionState.LEADING_VEHICLE_ENTRY
    ) {
      // Reset paths back to pre-truncated forms
      playCarLoops({
        parties,
        paths: parties.map(
          (p, i) => p._previous_path_for_undo_restore || p.path,
        ),
      });
      setParties(parties =>
        parties.map((p, i) => {
          p.path = p._previous_path_for_undo_restore || p.path;
          return p;
        }),
      );
      setReconstructionState(ReconstructionState.LEADING_VEHICLE_ENTRY);
      animateForSpeedEntry(true);
    }

    return true;
  };

  /**
   * Hook back button into undo stack.
   */
  useEffect(() => {
    if (registerBackHook) {
      registerBackHook(() => {
        if (offerUndo) {
          return undo();
        } else {
          return false;
        }
      });
    }
  }, [registerBackHook, offerUndo, undo]);

  /**
   * Resets to the PATH_ENTRY view.
   */
  const animateForPathEntry = () => {
    mapRef.current?.flyTo({
      center: collisionLocation,
      zoom: 18,
      pitch: 0,
      bearing: 0,
      speed: 0.4,
      essential: true,
    });
  };

  /**
   * Reset/zoom to the SPEED_ENTRY view for the current keyframe.
   */
  const animateForSpeedEntry = (reset?: boolean) => {
    const keyframeIndex = drawState.current.keyframe_index;
    for (const party of parties) {
      const kf = party.keyframes[keyframeIndex];
      if (!kf?.path?.length) {
        continue;
      }

      party.model.stop();
      party.model.setCoords(kf.path[0]);
      party.model.followPath({
        animation: 1,
        path: kf.path,
        duration: 250,
      });
      party.model.playAnimation();

      party.model.traverse(function (c: any) {
        if (c.isMesh) {
          c.visible = reset ? false : party === currentParty ? true : false;
        }
      });

      if (party === currentParty) {
        mapRef.current!.flyTo({
          center: [kf.position[0], kf.position[1] + 0.000025], // offset for top
          pitch: 45,
          bearing: 0,
          zoom: 20,
          speed: 0.4,
        });
      }
    }
    if (reset) {
      animateForPathEntry();
    }

    // Remove artefacts of later stages of the render process, e.g. in case we are undoing to
    // get back to here.
    if (mapRef.current?.hasImage('pulsing-dot')) {
      mapRef.current?.removeImage('pulsing-dot');
    }
    if (mapRef?.current?.getLayer('collision-points')) {
      mapRef.current?.removeLayer('collision-points');
    }
    if (mapRef?.current?.getSource('collision-points')) {
      mapRef.current?.removeSource('collision-points');
    }
    if (_pendingRotationAnimationFrame) {
      window.cancelAnimationFrame(_pendingRotationAnimationFrame);
    }
  };

  /**
   * Accept user input of the vehicle's movement state.
   */
  const enterMovementState = (movementState: MovementState) => {
    currentParty.movement_state = movementState;
    if (movementState === MovementState.MOVING) {
      currentParty.stationary_angle = undefined;
      currentParty.stationary_position = undefined;
    }

    if (
      currentPartyIndex === parties.length - 1 ||
      (currentPartyIndex === 0 && otherWasStationary)
    ) {
      if (parties.every(p => p.movement_state === MovementState.STATIONARY)) {
        setReconstructionState(ReconstructionState.STATIONARY_FACTORS_ENTRY);
      } else {
        const stationaryPartyIndex = parties.findIndex(
          p => p.movement_state === MovementState.STATIONARY,
        );
        if (stationaryPartyIndex !== -1) {
          setReconstructionState(ReconstructionState.STATIONARY_ANGLE_ENTRY);
          setCurrentPartyIndex(stationaryPartyIndex);
        } else {
          setReconstructionState(ReconstructionState.PATH_ENTRY);
          setCurrentPartyIndex(0);
        }
      }
    } else {
      setCurrentPartyIndex(currentPartyIndex + 1);
    }
  };

  /**
   * Accept user input of the direction of a stationary vehicle.
   */
  const enterStationaryDirection = (angle: number) => {
    currentParty.stationary_angle = angle > 180 ? -(360 - angle) : angle;
    currentParty.stationary_position = [
      collisionLocation.lng,
      collisionLocation.lat,
    ] as Position;
    setReconstructionState(ReconstructionState.PATH_ENTRY);
    setCurrentPartyIndex(0);
  };

  /**
   * Accept user input of the speed for the current keyframe, then trigger appropriate
   * map changes to move forward to the next entry point.
   */
  const enterSpeed = (speed: number) => {
    setSpeedEntryAvailable(false);
    let keyframeIndex = drawState.current.keyframe_index;
    if (speed < 0) {
      while (currentParty.keyframes[keyframeIndex]) {
        currentParty.keyframes[keyframeIndex].reverse = true;
        currentParty.keyframes[keyframeIndex].speed = Math.abs(speed);
        keyframeIndex++;
      }
    } else {
      currentParty.keyframes[keyframeIndex].reverse = false;
      currentParty.keyframes[keyframeIndex].speed = speed;
    }
    if (currentParty.keyframes[keyframeIndex + 1]) {
      drawState.current.keyframe_index += 1;
      animateForSpeedEntry();
    } else {
      drawState.current.keyframe_index = 0;
      setCurrentPartyIndex(currentPartyIndex + 1);
    }
    setTimeout(() => setSpeedEntryAvailable(true), 300);
  };

  /**
   * Accept user input of leading vehicle.
   */
  const enterLeadingVehicle = (id: string) => {
    for (const party of parties) {
      party.is_leading = party.id === id;
    }
    setParties(parties);
    enterAnalysisMode();
  };

  /**
   * On user touch down, enter drawing mode.
   */
  const startDraw = (e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) => {
    if (drawState.current.partyId) {
      e.preventDefault();
      drawState.current.drawing = true;
      drawState.current.path = [];
    }
  };

  /**
   * When user finishes, save input and step map forward.
   */
  const stopDraw = () => {
    if (drawState.current.drawing) {
      drawState.current.drawing = false;

      const feature = drawPathForParty({
        partyId: drawState.current.partyId,
        path: drawState.current.path,
        smooth: true,
      });

      if (!feature) {
        return;
      }

      const length = turf.lineDistance(feature, { units: 'meters' });
      if (length < MINIMUM_PATH_LENGTH_METERS) {
        window.alert(
          'Please draw a longer path for the vehicle, clearly showing its movement before the collision.',
        );
        window.analytics.track(
          'CollisionReconstruction: User finished drawing a path',
          {
            status: 'error_too_short',
          },
        );
        drawPathForParty({ partyId: drawState.current.partyId, path: [] });
        return;
      } else {
        window.analytics.track(
          'CollisionReconstruction: User finished drawing a path',
          {
            status: 'success',
          },
        );
      }

      drawState.current.path = null;

      const target = parties.find(p => p.id === drawState.current.partyId);
      if (target) {
        target.path = feature.geometry.coordinates as Position[];
        target._previous_path_for_undo_restore = null;
      }

      const success = processEnteredParties(parties);
      if (success) {
        setCurrentPartyIndex(i => i + 1);
      }
    }
  };

  /**
   * As user continues to touch the screen, record entered points.
   */
  const continueDrawWithPoint = (p: [number, number]) => {
    if (drawState.current.drawing) {
      drawState.current.path.push(p);
      drawPathForParty({
        partyId: drawState.current.partyId,
        path: drawState.current.path,
        smooth: false,
      });
    }
  };

  /**
   * Updates the state of the wizard with a new set of party data.
   */
  const processEnteredParties = (parties: any) => {
    const completedParties = parties.filter(
      (p: any) =>
        p.model &&
        (p.path?.length || p.movement_state === MovementState.STATIONARY),
    );

    // FIXME: only supports 2 parties
    if (completedParties.length === 2) {
      const targets = [
        {
          party: completedParties[0],
        },
        {
          party: completedParties[1],
        },
      ];

      const die = (message: string) => {
        window.alert(message);
        window.analytics.track('CollisionReconstruction: Process parties', {
          status: `error_${message}`,
        });
        const found = targets.find(
          t => t.party.id === drawState.current.partyId,
        );
        if (found) {
          found.party.path = [];
          drawPathForParty({
            partyId: found.party.id,
            path: [],
          });
        }
        return false;
      };

      for (const target of targets) {
        if (target.party.movement_state === MovementState.STATIONARY) {
          target.party.path = [target.party.stationary_position];
          continue;
        }

        target.party._original_path = target.party.path;

        if (
          target.party.path.length < 2 &&
          target.party.movement_state === MovementState.MOVING
        ) {
          // Die on short paths
          return die(
            'Please draw longer paths for both vehicles, clearly showing their movement.',
          );
        }

        const original = turf.lineString(target.party.path);
        const length = turf.lineDistance(original, { units: 'meters' });

        let newPath: turf.helpers.Position[] = [];
        for (let step = 0; step < length; step += 0.5) {
          newPath.push(
            turf.along(original, Math.min(step, length), { units: 'meters' })
              .geometry.coordinates,
          );
        }
        target.party.path = newPath;
      }

      const nearest = findNearestPoints(
        targets[0].party.path,
        targets[1].party.path,
      );

      const nearestPointError =
        nearest.distance > 5 // Points are more than 5m apart
          ? 'The vehicles do not collide. Please draw again.'
          : nearest.entries.some(
              (e, i) =>
                e < 2 &&
                targets[i].party.movement_state !== MovementState.STATIONARY,
            ) // Point index is one of the first 2 points
          ? "It looks like the collision point is at the beginning of one of the vehicle's paths. Please draw again, showing each vehicle's movement prior to the collision."
          : null;

      if (nearestPointError) {
        return die(nearestPointError);
      }

      // Preserve old path to allow for undo
      targets[0].party._previous_path_for_undo_restore = targets[0].party.path;
      targets[1].party._previous_path_for_undo_restore = targets[1].party.path;

      // Truncate paths based on the nearest/intersection point.
      const newPathA = targets[0].party.path.slice(0, nearest.entries[0]);
      const newPathB = targets[1].party.path.slice(0, nearest.entries[1]);

      // Die if the truncated paths are too short. Only look at paths >1 unit long,
      // i.e. movement state === MOVING.
      for (const path of [newPathA, newPathB].filter(p => p.length > 1)) {
        const length = turf.lineDistance(turf.lineString(path), {
          units: 'meters',
        });
        if (length < MINIMUM_PATH_LENGTH_METERS) {
          return die(
            'The paths before the collision point are too short. Please draw longer paths, showing more of the vehicles’ movement before they collided.',
          );
        }
      }

      targets[0].party.path = newPathA;
      targets[1].party.path = newPathB;

      const hasStationaryTarget = targets.some(
        t => t.party.movement_state === MovementState.STATIONARY,
      );

      for (const target of targets) {
        // Prompt the user for relative speeds at each spot.
        const path = target.party.path;
        const keyframeSpots = [0.1, hasStationaryTarget ? 0.85 : 0.9];
        target.party.keyframes =
          target.party.movement_state === MovementState.STATIONARY
            ? [
                {
                  percent: 0,
                  position: path[0],
                  path,
                  reverse: false,
                  speed: 0,
                },
              ]
            : keyframeSpots.map(pct => {
                const idx = Math.floor(path.length * pct);
                return {
                  percent: pct,
                  position: path[idx],
                  path: path.slice(
                    Math.max(0, idx - 3),
                    Math.min(path.length, idx + 3),
                  ),
                  reverse: false,
                  speed: null,
                };
              });
      }
    }

    setParties(parties);
    playCarLoops({ parties });

    window.analytics.track('CollisionReconstruction: Process parties', {
      status: `success`,
    });

    return true;
  };

  /**
   * Render vehicles looping on screen.
   */
  const playCarLoops = ({
    parties,
    paths,
    forceStart,
    forceDuration,
  }: {
    parties: any[];
    paths?: any[];
    forceStart?: number;
    forceDuration?: number;
  }) => {
    parties.forEach((p: any, i) => {
      const path = paths ? paths[i] : p.path;

      if (!p.model) {
        return;
      }

      p.model.stop();

      p.model.traverse(function (c: any) {
        if (c.isMesh) {
          c.material = c.material.clone();
          c.material.transparent = true;
          c.material.opacity = 1;
          c.visible =
            p.movement_state === MovementState.STATIONARY || path?.length > 0;
        }
      });
      if (tb && tb.map) {
        tb.map.repaint = true;
      }

      drawPathForParty({
        partyId: p.id,
        path,
        smooth: true,
      });

      if (forceStart) {
        const N_PAD = 20;
        let remaining = N_PAD;
        if (path.length > N_PAD) {
          while (remaining) {
            path[path.length - remaining] = path[path.length - N_PAD];
            remaining--;
          }
        }
      }

      let options = {
        animation: 1,
        path,
        duration: 2000,
      };

      const play = (start?: number) => {
        const getPointAtTime = (t: number) => {
          const idx = Math.max(Math.floor(options.path.length * t), 0);
          return tb.utils.projectToWorld(options.path[idx]);
        };

        if (p.movement_state === MovementState.STATIONARY) {
          p.model.setCoords(p.stationary_position);
          // Flip [-180,180] to map-relative angle, wherein 0 degrees is facing South
          const angle_sign = p.stationary_angle / Math.abs(p.stationary_angle);
          const angle_proj = angle_sign * (180 - Math.abs(p.stationary_angle));
          p.model.setRotation(angle_proj);
          if (tb && tb.map) {
            tb.map.repaint = true;
          }
        } else if (paths && options.path.length) {
          if (forceDuration) {
            options.duration = forceDuration;
          }
          p.model.animationQueue.push({
            type: 'followPath',
            parameters: {
              animation: 1,
              path: options.path,
              pathCurve: {
                getPointAt: getPointAtTime,
                getPoint: getPointAtTime,
                getTangentAt: (t: number) => {
                  const curr = Math.max(Math.floor(options.path.length * t), 1);
                  let prev = curr - 1;
                  while (
                    options.path[curr][0] === options.path[prev][0] &&
                    prev > 0
                  ) {
                    prev -= 1;
                  }

                  const prevV = tb.utils.projectToWorld(options.path[prev]);
                  const currV = tb.utils.projectToWorld(options.path[curr]);

                  let tangent = prevV.sub(currV);

                  const reverse = p?.keyframes?.[0]?.reverse === true;
                  if (!reverse) {
                    tangent = tangent.negate();
                  }

                  return tangent;
                },
              },
              start: start || Date.now(),
              expiration: (start || Date.now()) + options.duration,
              duration: options.duration,
              trackHeading: true,
              cb: () =>
                recordingMode
                  ? document.dispatchEvent(new Event('collision-recording-end'))
                  : play(start ? start + options.duration : undefined),
            },
          });
          if (tb && tb.map) {
            tb.map.repaint = true;
          }
        } else {
          if (options.path.length) {
            p.model.followPath(options, () => (recordingMode ? null : play()));
          }
        }

        if (options.path.length) {
          p.model.playAnimation(options);
        }
      };

      play(forceStart);
    });
  };

  /**
   * Draw initial 3D cars.
   */
  const drawInitial3DCars = () => {
    console.log('drawInitial3DCars', mapRef.current, tb);
    if (!mapRef.current || tb) {
      return;
    }
    const map = mapRef.current;

    map.addLayer({
      id: `3d`,
      type: 'custom',
      renderingMode: '3d',
      onAdd: function (map, mbxContext) {
        tb = new Threebox(map, mbxContext, {
          defaultLights: true,
          enableSelectingFeatures: false,
          enableSelectingObjects: false,
          enableDraggingObjects: false,
          enableRotatingObjects: false,
          enableTooltips: false,
        });
        (window as any).tb = tb;

        let options = {
          obj: carModel,
          type: 'mtl',
          scale: 1.25,
          units: 'meters',
          rotation: { x: 90, y: 180, z: 0 },
          anchor: 'center',
        };

        const loaders = parties.map(party => {
          return new Promise<void>(resolve => {
            tb.loadObj(options, function (model: any) {
              let partyModel = model.setCoords(origin);
              partyModel.traverse(function (c: any) {
                if (c.isMesh) {
                  c.material = c.material.clone();
                  c.material.color = new THREE.Color(party.color);
                }
              });
              tb.add(partyModel);
              party.model = partyModel;
              resolve();
            });
          });
        });

        parties.forEach(p => {
          map.addSource(p.id, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: { type: 'LineString', coordinates: [] },
                },
              ],
            },
          });
          map.addLayer(
            {
              id: p.id,
              type: 'line',
              source: p.id,
              paint: {
                'line-color': p.color,
                'line-width': 8,
                'line-opacity': 0.5,
              },
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
            },
            '3d',
          );
        });

        Promise.all(loaders).then(() => {
          playCarLoops({ parties });
        });
      },

      render: function () {
        tb?.update();
      },
    });
  };
  /**
   * Switch the map into "Analysis" mode.
   */
  const enterAnalysisMode = async () => {
    if (!mapRef.current) {
      return;
    }

    let traj: ReturnType<typeof generateTrajectories>;
    try {
      traj = generateTrajectories(parties);
    } catch (e) {
      if (e.message === NEEDS_LEADING_VEHICLE) {
        setReconstructionState(ReconstructionState.LEADING_VEHICLE_ENTRY);
        animateForSpeedEntry(true);
        return;
      } else {
        window.analytics.track(
          'CollisionReconstruction: Trajectory generation',
          {
            status: `error_${e.message}`,
          },
        );
        window.alert('Please draw longer paths for both vehicles.');
        console.error(e);
        reset?.();
        return;
      }
    }

    window.analytics.track('CollisionReconstruction: Trajectory generation', {
      status: `success`,
    });

    console.log(`Rendering computed trajectories`, traj);

    // Add pulsating dot at the collision location
    const size = 100;
    const pulsingDot = {
      context: null as CanvasRenderingContext2D | null,
      width: size,
      height: size,
      data: new Uint8ClampedArray(size * size * 4),
      onAdd: function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },
      render: function () {
        var duration = 1000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.7 * t + radius;
        var context = this.context;
        if (!context) {
          return;
        }

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2,
        );
        context.fillStyle = 'rgba(255, 200, 200,' + (1 - t) + ')';
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        mapRef.current?.triggerRepaint();
        return true;
      },
    };
    mapRef.current?.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
    mapRef.current?.addSource('collision-points', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: traj.collision_avg_position,
            },
          },
        ],
      },
    });
    mapRef.current?.addLayer({
      id: 'collision-points',
      type: 'symbol',
      source: 'collision-points',
      layout: {
        'icon-image': 'pulsing-dot',
      },
    });

    setReconstructionState(ReconstructionState.ANALYSIS);

    const _playLoopsForAnalysis = () =>
      playCarLoops({
        parties,
        paths: parties.map((_, i) => {
          return traj.ticks.map(t => t.entries[i].position);
        }),
        forceStart: Date.now() + 200,
        forceDuration: traj.duration > 10 ? 10_000 : traj.duration * 1000,
      });

    // Zoom to the precisely-entered collision point
    // If in recordingMode, wait until fully loaded and all tiles rendered before
    // starting the final playback.
    if (recordingMode) {
      mapRef.current.jumpTo({
        center: traj.collision_a?.position || collisionLocation,
        pitch: 0,
        bearing: 0,
        zoom: 19,
      });
      mapRef.current.once('idle', () => {
        _playLoopsForAnalysis();
        document.dispatchEvent(new Event('collision-recording-start'));
      });
    } else {
      _playLoopsForAnalysis();
      mapRef.current.flyTo({
        center: traj.collision_a?.position || collisionLocation,
        pitch: 45,
        bearing: 0,
        zoom: 19,
        speed: 0.4,
      });
    }

    // Render 3D buildings
    const addBuildings = () => {
      if (!mapRef.current) {
        return;
      }

      if (!mapRef.current.getSource('composite')) {
        mapRef.current.addSource('composite', {
          type: 'vector',
          url: 'mapbox://mapbox.mapbox-streets-v8',
        });
      }
      mapRef.current.addLayer(BUILDING_LAYER);
    };
    addBuildings();

    // Looping 360-degree rotation
    if (!playbackOnly) {
      setTimeout(() => {
        mapRef.current?.setPitch(45);
        function rotateCamera(timestamp: number, base?: number) {
          if (mapRef.current) {
            if (!base) base = timestamp;
            mapRef.current.rotateTo(((timestamp - base) / 100) % 360, {
              duration: 0,
            });
            _pendingRotationAnimationFrame = requestAnimationFrame(ts =>
              rotateCamera(ts, base),
            );
          }
        }
        rotateCamera(0);
      }, 1100);
    }

    setAnalysisData(traj);
  };

  /**
   * Given a path of points, render the path on the map.
   */
  const drawPathForParty = ({
    partyId,
    path,
    smooth,
  }: {
    partyId: string;
    path: any[];
    smooth?: boolean;
  }) => {
    const party = parties.find(p => p.id === partyId);
    if (party?.movement_state === MovementState.STATIONARY) {
      return;
    }

    const nonZeroIndex = path.findIndex(e => e[0] !== 0 || e[1] !== 0);
    path = path.slice(nonZeroIndex);

    let feature;

    if (path.length > 1) {
      feature = turf.lineString(path);

      if (smooth) {
        try {
          feature = turf.simplify(feature, {
            tolerance: CURVE_TOLERANCE,
            highQuality: true,
          });
        } catch (e) {
          const title =
            'CollisionReconstruction turf.simplify failed on feature';
          window.analytics.track(title, {
            path: JSON.stringify(path),
            error: e.message,
          });
          console.error(title);
          console.error(e);
        }
      }
    }

    (mapRef.current?.getSource(partyId) as mapboxgl.GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features: feature ? [feature] : [],
    });

    return feature;
  };

  if (
    reconstructionState === ReconstructionState.ANALYSIS &&
    initialData?.stationary_collision
  ) {
    const collision =
      initialData.stationary_collision as keyof typeof STATIONARY_COLLISION_TYPES;
    const description = `${
      playbackOnly ? `The user` : `You`
    } reported that both vehicles were stationary at the time of the collision. The underlying cause of the collision was "${
      STATIONARY_COLLISION_TYPES[collision] || 'unknown'
    }".`;

    return (
      <>
        {playbackOnly ? (
          <div>{description}</div>
        ) : (
          <>
            <Title title={description} />
            <div className="mt-6 flex flex-wrap justify-center">
              <button
                className="btn btn-blue sm:order-last"
                onClick={() => submit?.(initialData)}
              >
                That's right
              </button>
              <button className="btn btn-subtle sm:order-first" onClick={reset}>
                Start over
              </button>
            </div>
          </>
        )}
      </>
    );
  }

  if (reconstructionState === ReconstructionState.UNSUPPORTED_DEVICE) {
    return (
      <>
        <Title
          titleClassName="ClaimWorkflowInner"
          title="Unfortunately, your browser does not support the features required by the interactive collision reconstruction system."
        />
        <button className="btn btn-blue" onClick={() => submit?.(null)}>
          Continue
        </button>
      </>
    );
  }

  if (reconstructionState === ReconstructionState.MOVEMENT_STATE_ENTRY) {
    return (
      <>
        <Title
          key={`title-${currentPartyIndex}`}
          titleClassName="ClaimWorkflowInner"
          title={`Was ${currentParty?.name} moving or stationary at the time of the collision?`}
        />
        <div>
          <Select
            key={currentPartyIndex}
            className="ClaimWorkflowInner"
            primaryValue={null}
            updateValue={(_, v) => {
              if (v) {
                enterMovementState(v as MovementState);
              }
            }}
            step_component={{
              type: 'select',
              options: [
                {
                  value: MovementState.MOVING,
                  label: 'Moving',
                  icon_text: '🏎️💨',
                },
                {
                  value: MovementState.STATIONARY,
                  label: 'Stationary',
                  icon_text: '🛑',
                },
              ],
            }}
          />
        </div>
      </>
    );
  }

  if (reconstructionState === ReconstructionState.STATIONARY_ANGLE_ENTRY) {
    return (
      <>
        <Title
          titleClassName="ClaimWorkflowInner"
          title={`Got it. Which direction was ${currentParty?.name} facing while stationary before the incident?`}
        />
        <div className="w-full">
          <LocationDirection
            key={currentPartyIndex}
            className="ClaimWorkflowInner"
            primaryValue={null}
            updateValue={(_, v) => {
              enterStationaryDirection(v || 0);
            }}
            step_component={{
              type: 'location_direction',
              location_center: {
                latitude: collisionLocation.lat,
                longitude: collisionLocation.lng,
              },
              vehicle_color: currentParty.alternate_color,
              high_fidelity: true,
              existing_value: currentParty.stationary_angle
                ? currentParty.stationary_angle < 0
                  ? 360 + currentParty.stationary_angle
                  : currentParty.stationary_angle
                : undefined,
            }}
          />
        </div>
      </>
    );
  }

  if (reconstructionState === ReconstructionState.STATIONARY_FACTORS_ENTRY) {
    return (
      <>
        <Title
          titleClassName="ClaimWorkflowInner"
          title={`Got it. In this stationary collision, what happened?`}
        />
        <div>
          <Select
            className="ClaimWorkflowInner"
            primaryValue={[]}
            updateValue={(_, v) => {
              if (v) {
                submit?.({
                  stationary_collision: v,
                });
              }
            }}
            step_component={{
              type: 'select',
              grid_by: 1,
              options: Object.keys(STATIONARY_COLLISION_TYPES).map(k => ({
                value: k,
                label:
                  STATIONARY_COLLISION_TYPES[
                    k as keyof typeof STATIONARY_COLLISION_TYPES
                  ],
              })),
            }}
          />
        </div>
      </>
    );
  }

  const shouldShowInstructions =
    !hasSeenInstructions &&
    reconstructionState === ReconstructionState.PATH_ENTRY;

  return (
    <>
      {!playbackOnly ? (
        <Title
          key={currentParty?.id}
          titleClassName="mb-6 ClaimWorkflowInner"
          title={
            reconstructionState === ReconstructionState.ANALYSIS
              ? 'Does this look like what happened?'
              : reconstructionState ===
                ReconstructionState.LEADING_VEHICLE_ENTRY
              ? 'Which vehicle was [[in front]] at the moment the vehicles collided?'
              : reconstructionState === ReconstructionState.PATH_ENTRY &&
                currentParty
              ? hasSeenInstructions
                ? `${
                    currentPartyIndex === 0 ? 'Please' : 'Next, please'
                  } touch and drag to draw the
              path driven by ${currentParty.name}.`
                : `Now we're going to ask you to draw the paths of the involved vehicles. When you're ready to begin, tap "Continue".`
              : reconstructionState === ReconstructionState.SPEED_ENTRY &&
                currentParty
              ? `How fast was ${currentParty.name} moving ${
                  drawState.current.keyframe_index === 1
                    ? 'just before the collision'
                    : 'at this point'
                }?`
              : ''
          }
        />
      ) : null}
      <div
        className={classNames(
          'relative overflow-hidden transform translate-x-0 ClaimWorkflowInner',
          !recordingMode && 'rounded-md',
        )}
        style={{
          height: recordingMode
            ? '100%'
            : shouldShowInstructions
            ? 'auto'
            : 300,
          width: responsive ? '100%' : showStats ? 500 : 400,
          maxWidth: '100%',
        }}
      >
        {shouldShowInstructions ? (
          <div className="w-full h-full bg-white">
            <ExampleIllustration type="collision_reconstruction" />
            <button
              className="mt-4 btn btn-blue"
              onClick={() => setHasSeenInstructions(true)}
            >
              Continue
            </button>
          </div>
        ) : hasSeenInstructions &&
          reconstructionState === ReconstructionState.PATH_ENTRY ? (
          // Otherwise, show map move controls (currently disabled)
          <div className="text-white font-extrabold text-sm z-10">
            <div
              style={{ left: 0 }}
              className="ClaimWorkflowInner absolute top-6 bottom-6 w-6 z-10 bg-gray-500 bg-opacity-75 rounded-l-md flex items-center justify-center cursor-pointer select-none"
              onClick={() =>
                mapRef?.current?.panBy([-50, 0], undefined, {
                  updateCollisionLocation: true,
                })
              }
            >
              ←
            </div>
            <div
              style={{ right: 0 }}
              className="ClaimWorkflowInner absolute top-6 bottom-6 w-6 z-10 bg-gray-500 bg-opacity-75 rounded-r-md flex items-center justify-center cursor-pointer select-none"
              onClick={() =>
                mapRef?.current?.panBy([50, 0], undefined, {
                  updateCollisionLocation: true,
                })
              }
            >
              →
            </div>
            <div
              style={{ top: 0 }}
              className="ClaimWorkflowInner absolute left-0 right-0 h-6 z-10 bg-gray-500 bg-opacity-75 rounded-t-md flex items-center justify-center cursor-pointer select-none"
              onClick={() =>
                mapRef?.current?.panBy([0, -50], undefined, {
                  updateCollisionLocation: true,
                })
              }
            >
              ↑
            </div>
            <div
              style={{ bottom: 0 }}
              className="ClaimWorkflowInner absolute left-0 right-0 h-6 z-10 bg-gray-500 bg-opacity-75 rounded-b-md flex items-center justify-center cursor-pointer select-none"
              onClick={() =>
                mapRef?.current?.panBy([0, 50], undefined, {
                  updateCollisionLocation: true,
                })
              }
            >
              ↓
            </div>
          </div>
        ) : null}
        <div
          id="map"
          className={classNames(
            'w-full h-full',
            reconstructionState === ReconstructionState.SPEED_ENTRY &&
              'pointer-events-none',
            hasSeenInstructions && 'ClaimWorkflowInner',
          )}
        />
        {offerUndo ? (
          <div className="absolute bottom-2 left-2 ClaimWorkflowInner z-20">
            <span className="inline-flex rounded-md shadow-sm">
              <button
                onClick={offerUndo ? undo : reset}
                type="button"
                className={classNames(
                  'inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-gray-50 transition ease-in-out duration-150',
                  'bg-white text-gray-700 hover:text-gray-500 active:text-gray-800',
                )}
              >
                {offerUndo ? 'Undo' : 'Reset'}
              </button>
            </span>
          </div>
        ) : null}
        {playbackOnly ? (
          <div className="inline-grid grid-cols-2 gap-3 absolute bottom-2 right-3 select-none pointer-events-none">
            {[
              { color: YOU_COLOR, label: selfLabel || 'Filing party' },
              { color: OTHER_COLOR, label: otherPartyLabel || 'Other party' },
            ].map(({ color, label }) => (
              <div key={color} className="inline-flex items-center">
                <span
                  className="w-3 h-3 border-2 border-white rounded-full mr-2"
                  style={{ backgroundColor: color }}
                />
                <span
                  className={classNames(
                    'font-bold text-white',
                    recordingMode ? 'text-lg' : 'text-xs',
                  )}
                >
                  {label}
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {playbackOnly ? null : reconstructionState ===
        ReconstructionState.LEADING_VEHICLE_ENTRY ? (
        <>
          <div className={classNames('mt-4 grid -mx-2 grid-cols-2')}>
            {parties?.map((party, i) => {
              return (
                <button
                  key={party.id}
                  className="btn text-xs pb-3 pt-4 px-2 border-gray-300 text-gray-600 border-2"
                  style={{ backgroundColor: 'white' }}
                  onClick={() => enterLeadingVehicle(party.id)}
                >
                  <div
                    className="mx-auto h-5 w-5 rounded mb-2 shadow"
                    style={{ backgroundColor: party.alternate_color }}
                  />
                  {party.id === 'you' ? (
                    <>
                      <div className="font-bold">My vehicle</div>
                      <div>was in front</div>
                    </>
                  ) : party.id === 'other' ? (
                    <>
                      <div className="font-bold">The other vehicle</div>
                      <div>was in front</div>
                    </>
                  ) : (
                    ''
                  )}
                </button>
              );
            })}
          </div>
        </>
      ) : reconstructionState === ReconstructionState.SPEED_ENTRY &&
        currentParty ? (
        <>
          <div
            className={classNames(
              'mt-4 grid -mx-2',
              speedOptions?.filter(o => o.value).length === 2
                ? 'grid-cols-2'
                : 'grid-cols-3',
            )}
          >
            {speedOptions?.map(({ value }, i) => {
              return value ? (
                <button
                  key={value}
                  className={classNames(
                    'btn btn-blue text-xs py-2 px-2',
                    speedEntryAvailable ? '' : 'opacity-25 pointer-events-none',
                  )}
                  onClick={() => enterSpeed(value)}
                >
                  <div>{['Under', 'Around', 'Over'][i]}</div>
                  <div>
                    <span className="font-bold">{value}</span> MPH
                  </div>
                </button>
              ) : null;
            })}
          </div>
          <div>
            {
              // Only allow choosing Stopped if previous step was NOT stopped
              currentParty?.keyframes?.[drawState.current.keyframe_index - 1]
                ?.speed !== 0 &&
              // And as long as we are not on the last speed of a partial stationary collision
              !(
                parties.some(
                  p => p.movement_state === MovementState.STATIONARY,
                ) && drawState.current.keyframe_index === 1
              ) ? (
                <button
                  className={classNames(
                    'btn text-xs py-2 px-2 text-red-600 border-red-600 border-2 font-medium',
                    speedEntryAvailable ? '' : 'opacity-25 pointer-events-none',
                  )}
                  style={{ background: 'transparent' }}
                  onClick={() => enterSpeed(0)}
                >
                  <div>Stopped / not moving</div>
                </button>
              ) : null
            }
            {drawState.current.keyframe_index === 0 ? (
              <button
                className={classNames(
                  'ml-1 btn text-xs py-2 px-2 text-blue-600 border-blue-600 border-2 font-medium',
                  speedEntryAvailable ? '' : 'opacity-25 pointer-events-none',
                )}
                style={{ background: 'transparent' }}
                onClick={() => enterSpeed(-10)}
              >
                <div>Reversing</div>
              </button>
            ) : null}
          </div>
        </>
      ) : reconstructionState === ReconstructionState.ANALYSIS ? (
        <div className="mt-6 flex flex-wrap justify-center">
          <button
            className="btn btn-blue sm:order-last"
            onClick={() =>
              submit?.({
                parties: parties.map(p => ({
                  id: p.id,
                  movement_state: p.movement_state,
                  path: p.path,
                  keyframes: p.keyframes,
                  stationary_position: p.stationary_position,
                  stationary_angle: p.stationary_angle,
                  is_leading: p.is_leading,
                })),
              })
            }
          >
            That's right
          </button>
          <button className="btn btn-subtle sm:order-first" onClick={reset}>
            Start over
          </button>
        </div>
      ) : null}
      {showStats &&
      reconstructionState === ReconstructionState.ANALYSIS &&
      analysisData ? (
        <div className="mt-4 grid grid-cols-2 gap-4">
          {demoMode ? (
            <div className="col-span-2 font-medium text-gray-700">
              While Filing Party was performing an{' '}
              <strong>unprotected left turn</strong>, Other Party entered the
              intersection at ~35 MPH, resulting in an{' '}
              <strong>
                impact on the passenger side of Filing Party vehicle
              </strong>
              .
            </div>
          ) : null}
          <div className="col-span-2">
            <dl className="grid gap-4 grid-cols-10">
              {[
                {
                  key: 'impact_energy',
                  name: (
                    <span>
                      Impact energy (ΔKE
                      <small>max</small>)
                    </span>
                  ),
                  stat: (
                    <span
                      className={
                        analysisData.statistics.impact_energy > 100_000
                          ? 'text-red-500'
                          : 'text-blue-500'
                      }
                    >
                      {(analysisData.statistics.impact_energy / 1000).toFixed(
                        1,
                      )}{' '}
                      kJ
                    </span>
                  ),
                  className: 'col-span-4',
                },
                {
                  key: 'relative_speed',
                  name: 'Relative speed',
                  stat: `${analysisData.statistics.relative_speed_mph.toFixed(
                    1,
                  )} MPH`,
                  className: 'col-span-3',
                },
                {
                  key: 'impact_angle',
                  name: 'Impact angle',
                  stat: `${analysisData.statistics.impact_angle_degrees.toFixed(
                    1,
                  )}°`,
                  className: 'col-span-3',
                },
              ].map(item => (
                <div
                  key={item.key}
                  className={classNames(
                    'px-5 py-3 bg-white shadow rounded-lg overflow-hidden',
                    item.className,
                  )}
                >
                  <dt className="text-xs font-medium text-gray-500 whitespace-no-wrap">
                    {item.name}
                  </dt>
                  <dd className="text-xl font-semibold text-gray-900">
                    {item.stat}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {!hideCharts ? (
            <>
              <div>
                <div className="font-medium text-sm text-gray-600 text-center mb-1">
                  Reconstructed speed (MPH)
                </div>
                <ResponsiveContainer height={150}>
                  <LineChart syncId="timeChart" data={analysisData.ticks}>
                    <XAxis
                      dataKey="time"
                      allowDecimals={false}
                      type="number"
                      domain={[0, 'dataMax']}
                      tickFormatter={t => Math.round(t).toString()}
                    ></XAxis>
                    <YAxis
                      domain={[0, 'dataMax']}
                      tickFormatter={t => Math.round(t).toString()}
                      mirror
                    ></YAxis>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                      allowEscapeViewBox={{ x: true, y: false }}
                      contentStyle={{ fontSize: 11 }}
                      wrapperStyle={{ zIndex: 999 }}
                      labelFormatter={l => `t = ${l.toFixed(2)} seconds`}
                      formatter={(v: number) => `${v.toFixed(2)} MPH`}
                    />
                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="entries[0].speed_mph"
                      name={selfLabel || 'Filing party'}
                      stroke={YOU_COLOR}
                    />
                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="entries[1].speed_mph"
                      name={otherPartyLabel || 'Other party'}
                      stroke={OTHER_COLOR}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div>
                <div className="font-medium text-sm text-gray-600 text-center mb-1">
                  Journey progress (%)
                </div>
                <ResponsiveContainer height={150}>
                  <LineChart syncId="timeChart" data={analysisData.ticks}>
                    <XAxis
                      dataKey="time"
                      allowDecimals={false}
                      type="number"
                      domain={[0, 'dataMax']}
                      tickFormatter={t => Math.round(t).toString()}
                    ></XAxis>
                    <YAxis domain={[0, 1]} mirror></YAxis>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                      allowEscapeViewBox={{ x: true, y: false }}
                      contentStyle={{ fontSize: 11 }}
                      wrapperStyle={{ zIndex: 999 }}
                      labelFormatter={l => `t = ${l.toFixed(2)} seconds`}
                      formatter={(v: number) => `${(v * 100).toFixed(2)}%`}
                    />
                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="entries[0].progress"
                      name={selfLabel || 'Filing party'}
                      stroke={YOU_COLOR}
                    />
                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="entries[1].progress"
                      name={otherPartyLabel || 'Other party'}
                      stroke={OTHER_COLOR}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      <style>{`.mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right { display: none !important; }`}</style>
    </>
  );
};

export default CollisionReconstruction;
