import './Datepicker.css';

import classNames from 'classnames';
import React from 'react';
import DatePicker from 'react-datepicker';

interface DatepickerProps {
  value?: Date | null;
  onChange: (d: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  showTimeSelect?: boolean;
  placeholderText?: string;
  inline?: boolean;
  wide?: boolean;
}

const Datepicker: React.FC<DatepickerProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  showTimeSelect,
  placeholderText,
  inline,
  wide,
}) => {
  return (
    <DatePicker
      wrapperClassName={
        wide
          ? 'react-datepicker-wrapper--wide'
          : 'react-datepicker-wrapper--narrow'
      }
      selected={value}
      placeholderText={placeholderText || 'Enter a date...'}
      onChange={date => onChange(date as Date | null)}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      popperPlacement="bottom"
      dropdownMode="select"
      dateFormat={`MMMM d, yyyy${showTimeSelect ? ' h:mm a' : ''}`}
      showTimeSelect={showTimeSelect}
      minDate={minDate}
      maxDate={maxDate}
      inline={inline}
    />
  );
};

export default Datepicker;
